import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { UserRole } from "../../constants";
import { useContext, useState } from "react";
import { saveUser, updateUser } from "../../api/usersClient";
import { ModalContext } from "../../contexts/ModalContext";
import { UsersContext } from "../../contexts/UsersContextProvider";
import emailValidator from "../../validators/emailValidator";
import Button from "../../components/Button/Button";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

const UserDetailsModal = ({ userDetails = null }) => {
  const [user, setUser] = useState(userDetails);
  const [emailError, setEmailError] = useState(false);
  const [parentEmail1Error, setParentEmail1Error] = useState(false);
  const [parentEmail2Error, setParentEmail2Error] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const { hideModal } = useContext(ModalContext);
  const { users, setUsers } = useContext(UsersContext);

  const handleSelectChange = (e) => setUser({ ...user, role: e.target.value });

  const handleInputChange = (e) =>
    setUser({ ...user, [e.target.id]: e.target.value });

  const handleSave = async () => {
    setErrorMessage(null);
    const userToSend = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      role: user.role,
      parent_email1: user.parent_email1,
      parent_email2: user.parent_email2 || null,
    };

    if (userDetails) {
      await updateUser(userDetails.user_id, userToSend);
      setUsers((users) =>
        users.map((user) =>
          user.user_id === userDetails.user_id
            ? { ...user, ...userToSend }
            : user,
        ),
      );
      hideModal();
    } else {
      try {
        const response = await saveUser(userToSend);
        if (response?.statusCode === 409) {
          setErrorMessage(response.errorMessage);
        } else {
          setUsers([
            ...users,
            {
              user_id: response.user_id,
              login_count: 0,
              ...userToSend,
            },
          ]);
          hideModal();
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const validateEmail2 = (event) => {
    const value = event.target.value;

    if (!value) {
      setParentEmail2Error(false);
      return;
    }

    setParentEmail2Error(!emailValidator(value));
    return;
  };
  return (
    <React.Fragment>
      <h1>{userDetails ? "Edytuj użytkownika" : "Dodaj użytkownika"}</h1>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <Box marginBottom="16px">
        <TextField
          variant="outlined"
          id="first_name"
          label="Imię"
          fullWidth
          onChange={handleInputChange}
          value={user?.first_name || ""}
        />
      </Box>
      <Box marginBottom="16px" width="100%">
        <TextField
          variant="outlined"
          id="last_name"
          label="Nazwisko"
          fullWidth
          onChange={handleInputChange}
          value={user?.last_name || ""}
        />
      </Box>
      <Box marginBottom="16px" width="100%">
        <TextField
          variant="outlined"
          id="email"
          label="E-mail"
          fullWidth
          error={emailError}
          helperText={emailError ? "Adres e-mail jest niepoprawny" : false}
          onBlur={(e) => setEmailError(!emailValidator(e.target.value))}
          onChange={handleInputChange}
          value={user?.email || ""}
        />
      </Box>
      <Box>
        <FormControl
          variant="outlined"
          sx={{
            minWidth: 120,
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <InputLabel htmlFor="role-select">Rola</InputLabel>
          <Select
            native
            value={user?.role}
            onChange={handleSelectChange}
            label="Rola"
            inputProps={{
              name: "role",
              id: "role-select",
            }}
          >
            <option aria-label="None" value="" />
            <option value={UserRole.ADMIN}>Administrator</option>
            <option value={UserRole.MENTOR}>Mentor</option>
            <option value={UserRole.TEACHER}>Nauczyciel</option>
            <option value={UserRole.STUDENT}>Uczeń</option>
            <option value={UserRole.ALUMNI}>Absolwent</option>
          </Select>
        </FormControl>
      </Box>
      <Box marginBottom="16px" width="100%">
        <TextField
          variant="outlined"
          id="parent_email1"
          label="E-mail do opiekuna 1"
          fullWidth
          error={parentEmail1Error}
          helperText={
            parentEmail1Error ? "Adres e-mail jest niepoprawny" : false
          }
          onBlur={(e) => setParentEmail1Error(!emailValidator(e.target.value))}
          onChange={handleInputChange}
          value={user?.parent_email1 || ""}
        />
      </Box>
      <Box marginBottom="16px" width="100%">
        <TextField
          variant="outlined"
          id="parent_email2"
          label="E-mail do opiekuna 2"
          fullWidth
          error={parentEmail2Error}
          helperText={
            parentEmail2Error ? "Adres e-mail jest niepoprawny" : false
          }
          onBlur={validateEmail2}
          onChange={handleInputChange}
          value={user?.parent_email2 || ""}
        />
      </Box>
      <Box marginBottom="16px" width="100%">
        <Button
          disabled={emailError || parentEmail1Error || parentEmail2Error}
          onClick={handleSave}
          type="primary"
        >
          Wyślij
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default UserDetailsModal;
