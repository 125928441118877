import React, { useContext, useState } from "react";
import Textarea from "../../components/Textarea/Textarea";
import { UserContext } from "../../contexts/UserContextProvider";
import Button from "../../components/Button/Button";
import { withRouter } from "react-router-dom";
import { saveNote, updateNote } from "../../api/noteClient";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";

const SubjectNote = ({
  history,
  isVisible = false,
  note,
  setNote,
  isBlocked,
}: any) => {
  const subjectId = history.location.pathname.split("/")[4];
  const userId = history.location.pathname.split("/")[2];
  const { isStudent } = useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);
  const [initialNote, setInitialNote] = useState(note);

  if (!isVisible) {
    return null;
  }

  const resetForm = () => {
    setNote({ ...initialNote });
    setIsEditing(false);
  };

  const handleSave = async () => {
    let response;
    if (note.note_id) {
      response = await updateNote(note.note_id, {
        description: note.description,
      });
      setNote({ ...note, description: response.description });
    } else {
      response = await saveNote({
        subjectId,
        userId,
        note: { description: note.description },
      });
      setNote({ ...note, note_id: response.note_id, created_at: new Date() });
    }

    setIsEditing(false);
  };

  return (
    <React.Fragment>
      <h2>
        {!isStudent && (
          <FontAwesomeIcon style={{ marginRight: 15 }} icon={faEye} size="1x" />
        )}
        Dodatkowe informacje
      </h2>

      {isEditing ? (
        <React.Fragment>
          <Textarea
            id={note.note_id}
            label="Informacja dla ucznia"
            value={note.description}
            onChange={(value) => setNote({ ...note, description: value })}
          />

          <ButtonGroup>
            <Button type="primary" withRightMargin onClick={handleSave}>
              Zapisz
            </Button>
            <Button type="secondary" onClick={resetForm}>
              Anuluj
            </Button>
          </ButtonGroup>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!!note.note_id ? (
            <React.Fragment>
              <div
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{ __html: note.description }}
              />

              {(note.created_at || note.modified_at) && (
                <div className="goal__group">
                  <span className="goal__label goal__label--gray goal__label--small">
                    {note.modified_at ? "Zmodyfikowano dnia" : "Utworzono dnia"}
                  </span>
                  <span className="goal__author">
                    {note.modified_at
                      ? new Date(note.modified_at).toLocaleString()
                      : new Date(note.created_at).toLocaleString()}
                  </span>
                </div>
              )}

              {!isBlocked && !isStudent && (
                <Button
                  type="secondary"
                  onClick={() => setIsEditing(true)}
                  noPadding
                  withRightMargin
                >
                  Zmień
                </Button>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {!isStudent && !isBlocked && (
                <Button type="primary" onClick={() => setIsEditing(true)}>
                  Dodaj
                </Button>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(SubjectNote);
