import React from "react";

const TeacherMessage = () => (
  <React.Fragment>
    <p>
      Platforma IDER jest podstawowym, najważniejszym narzędziem, którym
      posługują się uczniowie, nauczyciele i mentorzy w ADE. Służy
      spersonalizowanemu zaplanowaniu edukacji każdemu uczniowi. IDER
      opracowywany jest na każdy trymestr i edytowany na bieżąco.
    </p>

    <strong>IDER powinien zawierać:</strong>
    <ol>
      <li>informację o uzdolnieniach, zainteresowaniach, planach ucznia;</li>
      <li>
        cele, które uczeń będzie realizował w trymestrze, wynikające z wymagań
        edukacyjnych podstawy programowej kształcenia ogólnego i wymagań
        przewidzianych Programem Dobrej Edukacji, dostosowane do możliwości
        ucznia, mając na uwadze przede wszystkim wymagania konieczne;
      </li>
      <li>
        aktualizowany na bieżąco opis realizacji celu musi być informacją
        zwrotną o poczynionych postępach, jakości wykonania zadań i powinien
        motywować ucznia do spełniania wymagań oraz zachęcać do dalszego
        rozwoju;
      </li>
      <li>
        trymestralne podsumowanie wszystkich celów - na koniec trymestru - TIoPU
        (Trymestralna Informacja o Postępach Ucznia);
      </li>
    </ol>

    <p>
      Każda wykonana przez ucznia praca powinna znaleźć odzwierciedlenie w
      ustnej lub pisemnej opisowej informacji zwrotnej (w IDERze), zwracającej
      uwagę na istotne cechy wykonanej pracy oraz zawierającej wskazówki dla
      ucznia do dalszej nauki.
    </p>

    <p>
      Po pierwszej klasyfikacji śródrocznej odbywa się spotkanie ucznia i
      rodziców z mentorem, na które, zależnie od potrzeb, mogą też zostać
      zaproszeni nauczyciele i specjaliści pracujący z uczniem. Jedno spotkanie,
      najlepiej po pierwszym trymestrze, jest obowiązkowe. Podczas rozmowy
      mentor przedstawia uczniowi i rodzicom opisowe informacje o postępach
      edukacyjnych oraz swoją ocenę postawy ucznia, odnosząc się do postawionych
      przed nim celów.
    </p>

    <p>
      Przy podsumowaniu trzeciego trymestru, poza krótką informacją opisową,
      nauczyciel proponuje uczniowi cyfrową roczną ocenę klasyfikacyjną.
    </p>

    <h2>JAK POWINNA WYGLĄDAĆ INFORMACJA ZWROTNA</h2>

    <ul>
      <li>
        <strong>według schematu:</strong> dwie gwiazdki (wskazanie pozytywów) i
        wskazówka (jak poprawić błędy lub do dalszej pracy); Jest to schemat,
        który powinien być stosowany także na indywidualnych pracach pisemnych
        uczniów. Nie stosujemy procentów, punktów, komentarzy nawiązujących do
        ocen (“dostatecznie, dobrze opanowałeś”). Wyjątek stanowią punkty
        przyznawane uczniom licealnym na pracach przygotowujących do matury.
      </li>
      <li>
        <strong>przykłady informacji zwrotnej do bieżącego celu:</strong>
      </li>
      <ul>
        <li>
          Widoczny jest brak biegłości rachunkowej, którą musisz poprawić –
          podaję zestaw dodatkowych zadań - podeślij je do końca listopada”,
        </li>
        <li>
          “Powinieneś jeszcze popracować, bo twoje umiejętności i wiedza w
          zakresie tego celu są niewystarczające. Musisz podejść do tego quizu
          jeszcze raz, proponuję najbliższy wtorek”;
        </li>
        <li>“Opanowałeś tę umiejętność”,</li>
        <li>“Świetnie sobie poradziłeś - gratuluję”,</li>
        <li>
          “Zdobyłeś tę umiejętność, ale popracuj jeszcze nad…, polecam zajrzeć
          jeszcze do…”,
        </li>
        <li>“Gratuluję, cenię wyjątkową aktywność na zajęciach”;</li>
      </ul>
      <li>
        <strong>przykłady informacji podsumowującej trymestr (TIoPU):</strong>
      </li>
      <ul>
        <li>
          “Każda praca oddana albo w ostatniej chwili albo już po terminie…, ale
          fizyka kwantowa jest twoją mocną stroną, poćwicz tylko arytmetykę.
        </li>
        <li>
          “Matematyka wymaga nie tylko zapału, ale głównie pracowitości i
          ćwiczeń. Dostrzegam i doceniam pracę, ale wyniki wszystkich dużych
          prac samodzielnych wskazują, że kilka celów z tego trymestru
          przeciągnie się jeszcze na grudzień. Poćwicz…”.
        </li>
        <li>
          “Na zajęciach jesteś bardzo aktywny i chętnie się wypowiadasz. Nie
          boisz się popełniać błędów i zadawać pytań, gdy coś jest niejasne -
          brawo! Masz bardzo dobry akcent. Widać, że nie masz problemu z
          przyswojeniem nowych zagadnień gramatycznych ani z tworzeniem
          wypowiedzi”.
        </li>
      </ul>
    </ul>
  </React.Fragment>
);

export default TeacherMessage;
