import React from "react";

const StudentMessage = () => {
  return (
    <React.Fragment>
      <p>
        Platforma IDER jest Twoim podstawowym, najważniejszym narzędziem, które
        towarzyszy Tobie, Twoim nauczycielom i mentorowi w roku szkolnym. W nim
        znajdziesz cele przedmiotowe oraz mentorskie na każdy trymestr oraz
        informacje o Twoich edukacyjnych postępach i rozwoju osobistym.
      </p>
      <p>
        Twoim zadaniem jest regularne, bieżące zapoznawanie się z wyznaczonymi
        przez nauczycieli oraz mentora celami i informacjami zwrotnymi oraz
        pracowaniem według pozostawionych tam wskazówek. Informacje o postępach
        są aktualizowane - bądź czujny i często zerkaj do swojego IDER-u.
        Pamiętaj, że w ADE nie kierujemy się zasadą 3xZ: zakuć, zdać, zapomnieć.
        Twoje cele mają pomóc Tobie zdobywać konkretne umiejętności, rozwijać
        zainteresowania i zachęcać do działania.{" "}
      </p>
      <br />
      <strong>Pamiętaj:</strong>
      <br />
      <ul>
        <li>
          w ADE nie kierujemy się zasadą 3xZ: zakuć, zdać, zapomnieć. Twoje cele
          mają pomóc Tobie zdobywać konkretne umiejętności, rozwijać
          zainteresowania i zachęcać do działania;
        </li>
        <li>
          Twoje cele mogą różnić się od celów Twoich kolegów, bo każdy ma inne
          możliwości, zasoby, zdolności, talenty, umiejętności;
        </li>
        <li>
          niezależnie od miejsca, z którego startujesz, w ADE patrzymy na
          postęp, którego dokonałeś, więc nie porównuj się z kolegami, a sam ze
          sobą sprzed tygodnia, miesiąca, roku itd. Jeśli stoisz w miejscu, to
          znaczy, że coś nie działa - wspólnie musimy się temu przyjrzeć.
        </li>
        <li>
          w ADE ważne są: Twój wkład pracy w zadanie, w postęp, w realizację
          celu, Twoja motywacja i zaangażowanie, samodzielność, Twoja postawa,
          systematyczna praca i uczciwość;
        </li>
        <li>
          że EDUKACJA - SZKOŁA - NAUKA nie muszą być przykrym obowiązkiem, a
          fajną przygodą - bardzo dużo zależy OD CIEBIE!
        </li>
      </ul>
    </React.Fragment>
  );
};

export default StudentMessage;
