import Textarea from "../../../components/Textarea/Textarea";
import { useState } from "react";
import Button from "../../../components/Button/Button";

export const CommentEditModal = ({ comment, onSave }) => {
  const [content, setContent] = useState(comment.content);

  const onCommentSave = () =>
    onSave({
      ...comment,
      content,
    });

  return (
    <>
      <h2>Edytuj komentarz</h2>
      <Textarea id="news-comment-input" value={content} onChange={setContent} />
      <Button type="primary" onClick={onCommentSave}>
        Zapisz
      </Button>
    </>
  );
};
