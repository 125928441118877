import "./Textarea.scss";
import ReactQuill from "react-quill";
import React from "react";

const Textarea = ({ id, label = null, value, onChange, disabled = false }) => (
  <div className="textarea">
    {label && (
      <label className="textarea__label" htmlFor={id}>
        {label}
      </label>
    )}

    <ReactQuill
      theme="snow"
      id={id}
      value={value}
      readOnly={disabled}
      onChange={onChange}
    />
  </div>
);

export default Textarea;
