import Textarea from "../../components/Textarea/Textarea";
import Button from "../../components/Button/Button";
import React, { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContextProvider";
import { sendGroupMessage } from "../../api/userGroupsClient";
import { FileInput } from "../../components/FileInput/FileInput";
import ReactQuill from "react-quill";

const GroupMessage = ({ groupId, onClose }) => {
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const { user } = useContext(UserContext);

  const sendMessage = async () => {
    const formData = new FormData();
    if (file) {
      formData.append("attachment", file);
    }

    formData.append("sender", `${user.first_name} ${user.last_name}`);
    formData.append("message", message);

    await sendGroupMessage(groupId, formData);

    onClose();
  };

  return (
    <div>
      <Textarea
        id="groupMessageTextArea"
        label="Wiadomość do grupy"
        value={message}
        onChange={setMessage}
      />

      <FileInput
        accept="image/*,.doc,.docx,.pdf"
        onUpload={(file) => setFile(file)}
      />

      <Button type="primary" onClick={sendMessage} disabled={!message.length}>
        Wyślij
      </Button>
    </div>
  );
};

export default GroupMessage;
