import { Link, withRouter } from "react-router-dom";

import { getSubjects } from "../../../api/subjectClient";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContextProvider";

const StudentNavigation = ({ history }) => {
  const { user } = useContext(UserContext);
  const [subjects, setSubjects] = useState(null);

  const _fetchSubjects = async () => {
    const response = await getSubjects();
    setSubjects(response.subjects);
  };

  const redirect = (subjectId) => {
    history.push(`/uzytkownicy/${user.user_id}/cele/${subjectId}`);
    history.go(0);
  };

  useEffect(() => {
    _fetchSubjects();
  }, []);

  if (!subjects) {
    return null;
  }

  return (
    <div className="navigation__links">
      <Link to="/ogloszenia">
        <span className="navigation__link">Tablica Ogłoszeń</span>
      </Link>
      {subjects.map((subject) => (
        <span
          onClick={() => redirect(subject.subject_id)}
          className="navigation__link"
        >
          {subject.name}
        </span>
      ))}
    </div>
  );
};

export default withRouter(StudentNavigation);
