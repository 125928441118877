import React, {useContext, useEffect, useState} from "react";
import Textarea from "../../components/Textarea/Textarea";
import { UserContext } from "../../contexts/UserContextProvider";
import Button from "../../components/Button/Button";
import {
  changePublishState,
  saveSummary,
  updateSummary,
} from "../../api/summariesClient";
import { withRouter } from "react-router-dom";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import MUI_Button  from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {MenuItem} from "@mui/material";
import {assignGrade} from "../../api/subjectClient";
import {TrimestersContext} from "../../contexts/TrimestersContext";

const GRADES = [
    'ZW',
    '1',
    '2',
    '2.5',
    '3',
    '3.5',
    '4',
    '4.5',
    '5',
    '5.5',
    '6'
]

const Summary = ({ history, summary, setSummary, isDisabled = false }: any) => {
  const subjectId = history.location.pathname.split("/")[4];
  const userId = history.location.pathname.split("/")[2];
  const { isStudent, isManagement, isTeacher } = useContext(UserContext);
  const { isLastTrimester } = useContext(TrimestersContext);
  const [isEditing, setIsEditing] = useState(false);
  const [initialSummary, setInitialSummary] = useState(summary);

  const [proposedGrade, setProposedGrade] = useState(initialSummary.proposed_grade || '');
  const [finalGrade, setFinalGrade] = useState(initialSummary.final_grade || '');

  const resetForm = () => {
    setSummary({ ...initialSummary });
    setIsEditing(false);
  };

  const handleSave = async () => {
    let response;
    if (summary.summary_id) {
      response = await updateSummary(summary.summary_id, {
        description: summary.description,
      });
      setSummary({ ...summary, description: response.description });
    } else {
      response = await saveSummary({
        subjectId,
        userId,
        summary: {
          description: summary.description,
        },
      });
      setSummary({ ...summary, summary_id: response.summary_id });
    }

    setIsEditing(false);
  };

  const togglePublish = async (state) => {
    const response = await changePublishState({
      summary_id: summary.summary_id,
      published: state,
    });

    setSummary({ ...summary, published: response.published });
  };

  const onGradeSave = async (grade) => {
    await assignGrade(subjectId, userId, grade);
  }

  useEffect(() => {
    setFinalGrade(summary.final_grade);
    setProposedGrade(summary.proposed_grade);
  }, [summary.final_grade, summary.proposed_grade])

  return (
    <React.Fragment>
      <h2>Podsumowanie Trymestru</h2>

      {isLastTrimester && (((isStudent && proposedGrade) || isManagement || isTeacher)) && (<Box width={270} mb="15px" display="flex" alignItems="center">
        <FormControl fullWidth sx={{marginRight: '10px'}}>
          <InputLabel id="proposed-grade">Ocena proponowana</InputLabel>
          <Select
              labelId="proposed-grade"
              id="proposed-grade-select"
              value={proposedGrade}
              disabled={isStudent || summary.published}
              label="Ocena proponowana"
              onChange={(e) => setProposedGrade(e.target.value)}
          >
            {GRADES.map(grade => (
                <MenuItem value={grade}>{grade}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {!isStudent && (<MUI_Button onClick={() => onGradeSave({proposed_grade: proposedGrade})} disabled={!proposedGrade || summary.published} variant="text">
          Zapisz
        </MUI_Button>)}
      </Box>)}

      {isLastTrimester && (((isStudent && finalGrade) || isManagement || isTeacher)) && (<Box width={270} mb="15px" display="flex" alignItems="center">

      <FormControl fullWidth sx={{marginRight: '10px'}}>
        <InputLabel id="final-grade">Ocena końcowa</InputLabel>
        <Select
            labelId="fiinal-grade"
            id="final-grade-select"
            value={finalGrade}
            disabled={isStudent || summary.published}
            label="Ocena końcowa"
            onChange={(e) => setFinalGrade(e.target.value)}
        >
          {GRADES.map(grade => (
              <MenuItem value={grade}>{grade}</MenuItem>
          ))}
        </Select>
      </FormControl>
        {!isStudent && (<MUI_Button onClick={() => onGradeSave({final_grade: finalGrade})} disabled={!finalGrade  || summary.published} variant="text">
          Zapisz
        </MUI_Button>)}
      </Box>)}

      {isEditing ? (
        <React.Fragment>
          <Textarea
            id={summary.summary_id}
            label="Informacja zwrotna"
            value={summary.description}
            onChange={(value) => setSummary({ ...summary, description: value })}
          />
          <ButtonGroup>
            <Button type="primary" withRightMargin onClick={handleSave}>
              Zapisz
            </Button>
            <Button type="secondary" onClick={resetForm}>
              Anuluj
            </Button>
          </ButtonGroup>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!!summary.summary_id ? (
            <React.Fragment>
              <div
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{ __html: summary.description }}
              />

              {!summary.published && !isStudent && (
                <Button
                  type="secondary"
                  onClick={() => setIsEditing(true)}
                  noPadding
                  withRightMargin
                >
                  Zmień
                </Button>
              )}
              {!isStudent && isManagement && summary.published && (
                <Button type="primary" onClick={() => togglePublish(false)}>
                  Cofnij do edycji
                </Button>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {!isStudent && !summary.published && (
                <Button
                  type="primary"
                  disabled={isDisabled}
                  onClick={() => setIsEditing(true)}
                >
                  {isDisabled ? "Uzupełnij informacje zwrotne" : "Dodaj"}
                </Button>
              )}
              {isStudent && <span>Jeszcze nie opublikowano podsumowania</span>}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(Summary);
