import { Link } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContextProvider";

import "./Navigation.scss";

const StaffNavigation = () => {
  const { isRoot, isAdmin, isMentor, isTeacher } = useContext(UserContext);

  const schoolYearGuard = isRoot || isAdmin;
  const usersGuard = isRoot || isAdmin || isMentor;
  const subjectCategoriesGuard = isRoot || isAdmin;
  const subjectsGuard = isRoot || isAdmin || isMentor || isTeacher;
  const userGroupsGuard = isRoot || isAdmin || isMentor || isTeacher;
  const summariesGuard = isRoot || isAdmin || isMentor;
  const reportsGuard = isRoot || isAdmin || isMentor || isTeacher;
  const newsGuard = isRoot || isAdmin || isMentor || isTeacher;
  // const diaryGuard = isRoot || isAdmin || isMentor || isTeacher;

  return (
    <div className="navigation__links">
      {/* {diaryGuard && <Link to="/dziennik"><span className="navigation__link navigation__link--highlight">Dziennik</span></Link>} */}
      {schoolYearGuard && (
        <Link to="/rokszkolny">
          <span className="navigation__link">Rok szkolny</span>
        </Link>
      )}
      {usersGuard && (
        <Link to="/uzytkownicy">
          <span className="navigation__link">
            {isMentor ? "Uczniowie" : "Użytkownicy"}
          </span>
        </Link>
      )}
      {newsGuard && (
        <Link to="/ogloszenia">
          <span className="navigation__link">Tablica Ogłoszeń</span>
        </Link>
      )}
      {userGroupsGuard && (
        <Link to="/uzytkownicy/grupy">
          <span className="navigation__link">Grupy</span>
        </Link>
      )}
      {subjectCategoriesGuard && (
        <Link to="/przedmioty/bloki">
          <span className="navigation__link">Bloki przedmiotowe</span>
        </Link>
      )}
      {subjectsGuard && (
        <Link to="/przedmioty">
          <span className="navigation__link">Przedmioty</span>
        </Link>
      )}
      {summariesGuard && (
        <Link to="/podsumowania">
          <span className="navigation__link">Podsumowania</span>
        </Link>
      )}
      {reportsGuard && (
        <Link to="/raporty">
          <span className="navigation__link">Raporty</span>
        </Link>
      )}
    </div>
  );
};

export default StaffNavigation;
