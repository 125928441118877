import React, { useEffect, useState } from "react";

import "./SubjectStudentsListControls.scss";
import { assignUserToSubject } from "../../api/subjectClient";
import Select from "../../components/Select/Select";

const SubjectUsersAddListControls = ({
  subjectId,
  usersNotAssigned,
  is_teacher,
  onSave,
}) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers(usersNotAssigned);
  }, [usersNotAssigned]);

  const assignUser = (selectedUserId) => {
    const dataToSend = {
      subject_id: subjectId,
      user_id: selectedUserId,
      is_teacher,
    };
    assignUserToSubject(dataToSend).then((_) => {
      const newUsers = users.filter((u) => u.user_id !== selectedUserId);
      onSave(users.find((u) => u.user_id === selectedUserId));
      setUsers(newUsers);
    });
  };

  const parseOptions = (users): { value: string; label: string }[] =>
    users.map((user) => ({
      value: user.user_id,
      label: `${user.first_name} ${user.last_name} (${user.email})`,
    }));

  return (
    <Select
      isButtonDisabled={users.length === 0}
      options={parseOptions(users)}
      method={assignUser}
      noOptionsMessage="brak użytkowników"
    />
  );
};

export default SubjectUsersAddListControls;
