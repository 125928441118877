import { API_URL } from "../constants";

export const getSchoolYears = async () => {
  try {
    const response = await global.fetch(`${API_URL}/years`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const addSchoolYear = async (year) => {
  try {
    const response = await global.fetch(`${API_URL}/years`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        year_started_at: year.year_started_at,
        year_ended_at: year.year_ended_at,
      }),
    });
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const activateSchoolYear = async (yearId) => {
  try {
    const response = await global.fetch(`${API_URL}/years/${yearId}/activate`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    return response.json();
  } catch (e) {
    console.log(e);
  }
};
