import React, {useContext, useEffect, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {ServerMessage} from "../../components/ServerMessage/ServerMessage";
import Container from "../../components/Container";
import {addUserDetails, deleteUser, getUser, muteUser, updateUser, updateUserDetails, updateUserEducationalGoals} from "../../api/usersClient";
import TextField from "@mui/material/TextField";
import {Divider, Grid, MenuItem} from "@mui/material";
import {API_URL, UserRole} from "../../constants";
import {UserContext} from "../../contexts/UserContextProvider";
import getRoleNicename from "../../helpers/getRoleNicename";
import ConfirmationModal from "../../components/ConfirmationModal";
import {ModalContext} from "../../contexts/ModalContext";
import {getSubjects} from "../../api/subjectClient";
import {FileInput} from "../../components/FileInput/FileInput";
import Avatar from "@mui/material/Avatar";
import CheckIcon from "@mui/icons-material/Check";

const OPTIONS = [
    {label: 'Administrator', value: UserRole.ADMIN},
    {label: 'Mentor', value: UserRole.MENTOR},
    {label: 'Nauczyciel', value: UserRole.TEACHER},
    {label: 'Uczeń', value: UserRole.STUDENT},
    {label: 'Alumni', value: UserRole.ALUMNI},
]

export const Profile = () => {
    const { isAdmin, isRoot, isMentor, isStudent, user: myself } = useContext(UserContext);
    const { showModal } = useContext(ModalContext);

    const [user, setUser] = useState(null);
    const [userName, setUserName] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [files, setFiles] = useState(null);
    const [avatarPreview, setAvatarPreview] = useState(null);

    const [serverMessage, setServerMessage] = useState(null);

    const location = useLocation();
    const history = useHistory();
    const userId = location.pathname.split("/")[2];

    const isMyProfile = myself.user_id === userId;

    const _fetchUser = async () => {
        try {
            const fetchedUser = await getUser(userId);
            setUser(fetchedUser);
            setUserName(`${fetchedUser.first_name} ${fetchedUser.last_name}`);
        } catch (err) {
            setServerMessage({
                type: "ERROR",
                message: "Nie udało się pobrać danych użytkownika.",
            });
        }
    };

    const _fetchSubjects = async () => {
        try {
            const fetchedSubjects = await getSubjects(userId, 'profile');
            setSubjects(fetchedSubjects.subjects);
        } catch (err) {
            setServerMessage({
                type: "ERROR",
                message: "Nie udało się pobrać przedmiotów",
            });
        }
    };

    const handleSelectChange = (e) => setUser({ ...user, role: e.target.value });

    const handleInputChange = (e) =>
        setUser({ ...user, [e.target.id]: e.target.value });

    const handleMuteUser = async (userId, muted) => {
        try {
            await muteUser(userId, { muted }).then((_) => {
                setServerMessage({
                    type: "SUCCESS",
                    message: `Możliwość komentowania użytkownika została ${
                        muted ? "odblokowana" : "zablokowana"
                    }.`,
                });

                setUser({...user, muted})
            });
        } catch (err) {
            setServerMessage({
                type: "ERROR",
                message: `Nie udało się ${
                    muted ? "odblokować" : "zablokować"
                } komentowania użytkownika. Spróbuj ponownie później.`,
            });
        }
    };

    const showMuteModal = (userId, muted) => {
        showModal(ConfirmationModal, {
            action: () => handleMuteUser(userId, muted),
            text: `Czy na pewno chcesz ${
                muted ? "odblokować" : "zablokować"
            } komentowanie użytkownika?`,
        });
    };

    const handleUserDelete = async (userId) => {
        try {
            await deleteUser(userId).then((_) => {
                history.push('/uzytkownicy')
            });
        } catch (err) {
            setServerMessage({
                type: "ERROR",
                message: "Nie udało się usunąć użytkownika. Spróbuj ponownie później.",
            });
        }
    };

    const showDeleteModal = (userId) => {
        showModal(ConfirmationModal, {
            action: () => handleUserDelete(userId),
            text: "Czy na pewno chcesz usunąć użytkownika? Operacja jest nieodwracalna",
        });
    };

    const onUserGeneralDetailsSubmit = async () => {
        const payload = {
            first_name: user.first_name,
            last_name: user.last_name,
            role: user.role,
            parent_email1: user.parent_email1 || '',
            parent_email2: user.parent_email2 || '',
        }
        try {
            await updateUser(userId, payload);

            setServerMessage({
                type: "SUCCESS",
                message: "Dane użytkownika zostały zmienione",
            });
        } catch {
            setServerMessage({
                type: "ERROR",
                message: "Nie udało się zaktualizować danych użytkownika",
            });
        }
    }

    const onUserAdditionalDetailsSubmit = async () => {

        const payload = {
            interests: user.interests || '',
            additional_classes: user.additional_classes || '',
            future_goals: user.future_goals || '',
            achievements: user.achievements || '',
            work_methods: user.work_methods || '',
        }
        try {
            if(user?.user_details_id){
                await updateUserDetails(userId, payload);
            }else{
                await addUserDetails(userId, payload);

            }

            setServerMessage({
                type: "SUCCESS",
                message: "Dane użytkownika zostały zmienione",
            });
        } catch {
            setServerMessage({
                type: "ERROR",
                message: "Nie udało się zaktualizować danych użytkownika",
            });
        }
    }

    const goToPasswordChange = () =>  window.location.assign('/ustaw-haslo');

    const onImageChange = (files) => {
        setFiles(files);
        if (files && files[0]) {
            setAvatarPreview(URL.createObjectURL(files[0]));
        }
    }

    const onAvatarSave = async () => {
        const formData = new FormData();
        if (files) {
            for (const single_file of files) {
                formData.append("files_to_upload", single_file);
            }

            formData.append("user_id", userId);

            await fetch(`${API_URL}/files/upload?uploadType=AVATAR`, {
                method: "POST",
                credentials: "include",
                body: formData,
            });
        }
    }

    const onEducationalGoalsChange = async () => {
        
        try {
            if(user?.educational_goals_url){
                await updateUserEducationalGoals(userId, {educational_goals_url: user.educational_goals_url});
            }
            setServerMessage({
                type: "SUCCESS",
                message: "Cele edukacyjne użytkownika zostały zmienione",
            });
        } catch {
            setServerMessage({
                type: "ERROR",
                message: "Nie udało się zaktualizować danych użytkownika",
            });
        }
    }

    useEffect(() => {
        if(isStudent && userId !== myself.user_id){
            window.location.assign('/');
        }
    }, [])

    useEffect(() => {
        setUser(null);
        setSubjects([]);
        setUserName('');
        _fetchUser();
        _fetchSubjects();
    }, [location.pathname])


    if(!user){
        return null;
    }

    return (
        <Container>
            <Box display="flex" alignItems="center"
                 sx={{
                     flexDirection: {
                         xs: 'column',
                         md: 'row',
                     },
                     alignItems: {
                         xs: 'flex-start',
                         md: 'center'
                     },
                     marginBottom: {
                         xs: '32px',
                         md: '8px'
                     }
                 }}
            >
                <h1>{userName}</h1>
                {isMyProfile && (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{
                            marginLeft: {
                                xs: '0',
                                md: '16px'
                            }
                        }}
                        onClick={goToPasswordChange}
                    >
                        Zmień hasło
                    </Button>
                )}
                {(!isMyProfile && (isRoot || isAdmin)) && (<Box
                    sx={{
                        marginLeft: {
                            xs: '0',
                            md: '16px'
                        }
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{
                            marginRight: '8px'
                        }}
                        onClick={() => showMuteModal(userId, !user.muted)}
                    >
                        {user.muted ? 'Odblokuj komentowanie' : 'Zablokuj komentowanie'}
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => showDeleteModal(userId)}
                    >
                        Archiwizuj
                    </Button>
                </Box>)}
            </Box>
            <ServerMessage messageObj={serverMessage} />
            <Grid container spacing={{md: 16}}>
                <Grid item xs={12} md={6}>

                    <Box>
                        <Box display="flex" mb="32px" alignItems="center">
                            <Avatar
                                src={avatarPreview || `/avatars/${user.avatar_url}` || undefined}
                                sx={{
                                    marginRight: '32px',
                                    width: '100px',
                                    height: '100px'
                                }}
                            />
                            {(isRoot || isMentor || isAdmin) && (<FileInput
                                accept="image/*"
                                onUpload={onImageChange}
                                onDelete={() => setAvatarPreview(null)}
                            />)}
                        </Box>
                        {(isRoot || isMentor || isAdmin) && (<Button
                            variant="outlined"
                            onClick={onAvatarSave}
                            sx={{maxWidth: '100px'}}
                            color="success"
                            disabled={!files?.length}
                        >
                            Zapisz
                        </Button>)}
                    </Box>

                    <h2>Dane podstawowe</h2>
                    <Box>
                        <Box component="form" display="flex" mb="32px" flexDirection="column" noValidate >
                            <TextField
                                id="first_name"
                                label="Imię"
                                onChange={handleInputChange}
                                defaultValue={user.first_name}
                                disabled={!isRoot && !isAdmin}
                                sx={{
                                    marginBottom: '16px'
                                }}
                            />

                            <TextField
                                id="last_name"
                                label="Nazwisko"
                                defaultValue={user.last_name}
                                onChange={handleInputChange}
                                disabled={!isRoot && !isAdmin}
                                sx={{
                                    marginBottom: '16px'
                                }}
                            />

                            <TextField
                                id="email"
                                label="E-mail"
                                defaultValue={user.email}
                                onChange={handleInputChange}
                                disabled
                                sx={{
                                    marginBottom: '16px'
                                }}
                            />

                            {isMyProfile && (
                                <TextField
                                    id="role"
                                    label="Rola"
                                    onChange={handleInputChange}
                                    disabled
                                    defaultValue={getRoleNicename(user.role)}
                                    sx={{
                                        marginBottom: '16px'
                                    }}
                                />)}

                            {(!isMyProfile && (isRoot || isAdmin)) && (<TextField
                                id="role"
                                select
                                label="Rola"
                                onChange={handleSelectChange}
                                defaultValue={user.role}
                                sx={{
                                    marginBottom: '16px'
                                }}
                            >
                                {OPTIONS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>)}

                            {!isStudent && (<TextField
                                id="parent_email1"
                                label="E-mail do opiekuna 1"
                                onChange={handleInputChange}
                                defaultValue={user.parent_email1 || ''}
                                sx={{
                                    marginBottom: '16px'
                                }}
                            />)}

                            {!isStudent && (<TextField
                                id="parent_email2"
                                label="E-mail do opiekuna 2"
                                onChange={handleInputChange}
                                defaultValue={user.parent_email2 || ''}
                                sx={{
                                    marginBottom: '16px'
                                }}
                            />)}

                            {(isRoot || isAdmin) && (<Button variant="outlined" onClick={onUserGeneralDetailsSubmit}  sx={{maxWidth: '100px'}} color="success">
                                Zapisz
                            </Button>)}
                        </Box>

                        <h2>Cele edukacyjne na obowiązujący rok szkolny</h2>
                        <Divider />

                        <Box component="form" display="flex" mb="32px" flexDirection="column" noValidate >
                            {(isStudent && !!user.educational_goals_url) &&(
                                <Box mt="16px"><a href={user.educational_goals_url} target="_blank">{user.educational_goals_url}</a></Box>
                            )}
                            {isStudent && !user.educational_goals_url && (
                                <Box mt="16px"><span>Adres nie został jeszcze uzupełniony przez nauczyciela.</span></Box>
                            )}
                            {!isStudent && (<>
                            <TextField
                                id="educational_goals_url"
                                label="Wprowadź adres do celów edukacyjnych na obowiązujący rok szkolny"
                            
                                sx={{
                                    marginBottom: '16px'
                                }}
                                defaultValue={user.educational_goals_url}
                                onChange={handleInputChange}
                            />
                            <Button disabled={!user.educational_goals_url} variant="outlined" onClick={onEducationalGoalsChange} sx={{maxWidth: '100px'}} color="success">
                                Zapisz
                            </Button>
                            </>)}
                        </Box>

                        <h2>Dodatkowe informacje</h2>
                        <Divider />

                        <Box component="form" display="flex" mb="32px" flexDirection="column" noValidate >
                            <TextField
                                id="interests"
                                label="Zainteresowania / Uzdolnienia / Talenty"
                                multiline
                                rows={4}
                                sx={{
                                    marginBottom: '16px'
                                }}
                                defaultValue={user.interests}
                                onChange={handleInputChange}
                            />

                            <TextField
                                id="additional_classes"
                                label="Zajęcia dodatkowe / Rozszerzenia"
                                multiline
                                rows={4}
                                sx={{
                                    marginBottom: '16px'
                                }}
                                defaultValue={user.additional_classes}
                                onChange={handleInputChange}
                            />

                            <TextField
                                id="future_goals"
                                label="Plany / Cele edukacyjne"
                                multiline
                                rows={4}
                                sx={{
                                    marginBottom: '16px'
                                }}
                                defaultValue={user.future_goals}
                                onChange={handleInputChange}
                            />

                            <TextField
                                id="work_methods"
                                label="Najskuteczniej uczę się wtedy, gdy..."
                                multiline
                                rows={4}
                                sx={{
                                    marginBottom: '16px'
                                }}
                                defaultValue={user.work_methods}
                                onChange={handleInputChange}
                            />

                            <TextField
                                id="achievements"
                                label="Doświadczenia / Osiągnięcia / Wolontariaty / Projekty"
                                multiline
                                rows={4}
                                sx={{
                                    marginBottom: '16px'
                                }}
                                defaultValue={user.achievements}
                                onChange={handleInputChange}
                            />

                            <Button variant="outlined" onClick={onUserAdditionalDetailsSubmit} sx={{maxWidth: '100px'}} color="success">
                                Zapisz
                            </Button>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}
                      sx={{
                          paddingTop: {
                              sm: '0',
                          }
                      }}
                >
                    <h2>Moje przedmioty</h2>
                    <Stack direction="column" spacing={2}>
                        {!subjects.length && <p>Brak przypisanych przedmiotów</p>}

                    {subjects.map(subject => (
                            <Link to={`/uzytkownicy/${userId}/cele/${subject.subject_id}`}>

                                <Chip sx={{backgroundColor: 'orange', marginLeft: 0}} key={subject.subject_id} size="medium" label={<Box display="flex" alignItems="center">{subject.summary_published && (<CheckIcon fontSize="small"/>)} {subject.name}</Box>} />
                            </Link>
                        ))}
                    </Stack>
                </Grid>
            </Grid>

        </Container>
    )
}