import {Link, useHistory, useLocation} from "react-router-dom";
import logotyp from "../../../assets/logo.svg";

import "./Navigation.scss";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContextProvider";
import StudentNavigation from "./StudentNavigation";
import StaffNavigation from "./StaffNavigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {logoutUser} from "../../../api/authClient";

const Navigation = () => {
  const location = useLocation();
  const history = useHistory();

  const { isStudent, clearUser } = useContext(UserContext);
  const [linksVisible, setLinksVisible] = useState(window.innerWidth > 768);
  const [initialVisit, setInitialVisit] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setLinksVisible(window.innerWidth > 768);
    });

    window.removeEventListener("resize", () => {});
  }, []);

  useEffect(() => {
    if (!initialVisit) {
      setInitialVisit(true);
    } else {
      if (window.innerWidth <= 768) {
        setLinksVisible(false);
      }
    }
  }, [location]);

  const onLogout = async () => {
    logoutUser().then((_) => {
      history.push("/logowanie");
    });

    clearUser();
  };

  return (
    <nav className="navigation">
      <div className="navigation__header">
        <Link to="/">
          <div className="navigation__logoHandler">
            <img
              className="navigation__logo"
              alt="IDER logotyp"
              src={logotyp}
            />
          </div>
        </Link>
        <FontAwesomeIcon
          onClick={() => setLinksVisible(!linksVisible)}
          icon={faBars}
          cursor="pointer"
          size="2x"
          className="navigation__mobileToggler"
        />
      </div>
      <div
        className="navigation__linksHandler"
        style={{ display: linksVisible ? "flex" : "none" }}
      >
        {isStudent ? <StudentNavigation /> : <StaffNavigation />}

        <button className="navigation__logout" onClick={onLogout}>
            Wyloguj się
        </button>
      </div>
    </nav>
  );
};

export default Navigation;
