import React, { ReactElement } from "react";

import styles from "./AuthContainer.module.css";

const AuthContainer = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}): ReactElement => {
  return (
    <section className={styles.authWrapper}>
      <div className={styles.authContainer}>{children}</div>
    </section>
  );
};

export default AuthContainer;
