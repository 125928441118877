import "./SuccessMessage.scss";

const SuccessMessage = ({ message }) => {
  if (!message) {
    return null;
  }
  return <span className="successMessage">{message}</span>;
};

export default SuccessMessage;
