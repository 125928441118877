import { useContext } from "react";
import ConfirmationModal from "../../components/ConfirmationModal";
import { ModalContext } from "../../contexts/ModalContext";
import "./News.scss";
import { deleteNews } from "../../api/newsClient";
import { UserContext } from "../../contexts/UserContextProvider";
import { NewsForm } from "./NewsForm";
import { API_URL } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const SingleNews = ({
  news,
  setServerMessage,
  updateNews,
  removeNews,
}) => {
  const { showModal } = useContext(ModalContext);
  const { user, isRoot, isAdmin } = useContext(UserContext);

  const downloadFile = (fileName: string) => {
    fetch(`${API_URL}/files/${fileName}`, { credentials: "include" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => console.error(err));
  };

  const _deleteNews = async (newsId) => {
    await deleteNews(newsId).then(() => {
      removeNews(newsId);
    });
  };

  const showDeleteModal = () => {
    showModal(ConfirmationModal, {
      action: () => _deleteNews(news.news_id),
      text: "Czy na pewno chcesz usunąć ogłoszenie? Operacja jest nieodwracalna",
    });
  };

  const showEditModal = () => {
    showModal(NewsForm, {
      news,
      setServerMessage,
      updateNews,
    });
  };

  return (
    <div className="news__single">
      <div dangerouslySetInnerHTML={{ __html: news.message }} />

      {news.attachment1 && (
        <button
          className="news__attachment"
          onClick={() => downloadFile(news.attachment1)}
        >
          <FontAwesomeIcon icon={faFile} />
          <span>{news.attachment1}</span>
        </button>
      )}
      {news.attachment2 && (
        <button
          className="news__attachment"
          onClick={() => downloadFile(news.attachment2)}
        >
          <FontAwesomeIcon icon={faFile} />
          <span>{news.attachment2}</span>
        </button>
      )}
      <div className="news__groupDetails">
        <strong>Grupy:</strong> {news.groups_names}
      </div>
      <div className="news__metaDetails">
        <span>
          <strong>Autor</strong>: {news.author}
        </span>
        <span>
          <Link to={`/ogloszenia/${news.news_id}`}>Komentarze ({news.commentsCount}) |</Link>
          {(isRoot || isAdmin || user.user_id === news.user_id) && (
            <button className="news__editButton" onClick={showEditModal}>
              Edytuj
            </button>
          )}
          {(isAdmin || isRoot) && (
            <button className="news__removeButton" onClick={showDeleteModal}>
              Usuń
            </button>
          )}
          <strong>{news.subject_name}</strong> |{" "}
          {new Date(news.created_at).toLocaleDateString()}
        </span>
      </div>
    </div>
  );
};
