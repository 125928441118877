import React, { useContext, useState } from "react";
import Field from "../../components/Field/Field";
import Button from "../../components/Button/Button";
import Textarea from "../../components/Textarea/Textarea";
import { UserContext } from "../../contexts/UserContextProvider";

import "./Goal.scss";
import { changeGoalVisibility } from "../../api/goalsClient";
import classNames from "classnames";

const Goal = ({
  goal,
  editMode = false,
  updateGoal = null,
  onApiUpdate = null,
  onDelete,
  isBlocked = false,
}) => {
  const [isEditing, setIsEditing] = useState(editMode);
  const [initialState, setInitialState] = useState(goal);
  const { isStudent } = useContext(UserContext);
  const isTempGoal = goal.goal_id.startsWith("temp");

  const handleGoalUpdate = (updatedValue): void => {
    updateGoal({
      goal_id: goal.goal_id,
      ...updatedValue,
    });
  };

  const openEditMode = () => setIsEditing(true);

  const closeEditMode = () => {
    updateGoal(initialState);
    setIsEditing(false);
  };

  const onApiCall = (goal) => {
    onApiUpdate(goal);
    setIsEditing(false);
  };

  const changeVisibility = async (goal) => {
    await changeGoalVisibility({ goal_id: goal.goal_id, hidden: !goal.hidden });

    handleGoalUpdate({
      hidden: !goal.hidden,
    });
  };

  if (isEditing) {
    return (
      <div className="goal__wrapper">
        <div className="goal goal__editMode">
          <Field
            id={goal.goal_id}
            label="Opis celu"
            value={goal.description}
            onChange={(val) => handleGoalUpdate({ description: val })}
          />
          <Textarea
            id={goal.goal_id}
            label="Informacja zwrotna"
            value={goal.result}
            onChange={(val) => handleGoalUpdate({ result: val })}
          />
        </div>
        <div className="goal__controls">
          {isTempGoal ? (
            <Button type="secondary" onClick={() => onDelete(goal.goal_id)}>
              Usuń
            </Button>
          ) : (
            <>
              <Button type="secondary" onClick={() => onApiCall(goal)}>
                Aktualizuj
              </Button>
              <Button type="secondary" onClick={closeEditMode}>
                Anuluj
              </Button>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames({
        goal: true,
        "goal--hidden": goal.hidden,
      })}
    >
      <div className="goal__group">
        <span className="goal__label">Opis celu</span>
        <div
          className="goal__description"
          dangerouslySetInnerHTML={{ __html: goal.description }}
        />
      </div>

      <div className="goal__group">
        <span className="goal__label">Informacja zwrotna</span>
        {goal.result ? (
          <div
            className="goal__result"
            dangerouslySetInnerHTML={{ __html: goal.result }}
          />
        ) : (
          <p className="goal__no-result">nie zdefiniowano</p>
        )}
      </div>

      {goal.author && (
        <div className="goal__group">
          <span className="goal__label goal__label--gray goal__label--small">
            Wystawione przez
          </span>
          <span className="goal__author">{goal.author}</span>
        </div>
      )}

      {(goal.created_at || goal.modified_at) && (
        <div className="goal__group">
          <span className="goal__label goal__label--gray goal__label--small">
            {goal.modified_at ? "Zmodyfikowano dnia" : "Utworzono dnia"}
          </span>
          <span className="goal__author">
            {goal.modified_at
              ? new Date(goal.modified_at).toLocaleString()
              : new Date(goal.created_at).toLocaleString()}
          </span>
        </div>
      )}

      {!isStudent && !isBlocked && (
        <div className="goal__manageControls">
          <Button
            type="secondary"
            onClick={openEditMode}
            noPadding
            withRightMargin
          >
            Edytuj
          </Button>
          <Button
            type="danger"
            onClick={() => onDelete(goal.goal_id)}
            noPadding
            withRightMargin
          >
            Usuń
          </Button>
          <Button
            type="secondary"
            onClick={() => changeVisibility(goal)}
            noPadding
          >
            {goal.hidden ? "Odkryj" : "Ukryj"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Goal;
