import { useEffect, useState, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import {
  addComment,
  editComment,
  getNewsDetails,
  removeComment,
} from "../../api/newsClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { downloadFile } from "../../helpers/downloadFile";
import Container from "../../components/Container";
import { CommentInput } from "./comments/CommentInput";
import { UserContext } from "../../contexts/UserContextProvider";
import { ModalContext } from "../../contexts/ModalContext";
import { CommentEditModal } from "./comments/CommentEditModal";

const News = ({ history }) => {
  const { user, isManagement } = useContext(UserContext);
  const { showModal, hideModal } = useContext(ModalContext);

  const [news, setNews] = useState(null);
  const [comments, setComments] = useState(null);
  const newsId = history.location.pathname.split("/")[2];


  const checkCommentAuthor = (commentAuthorId) => {
    if (commentAuthorId === user.user_id && !user.muted) {
      return true;
    }

    return false;
  };

  const _fetchNewsDetails = async () => {
    try {
      const fetchedNewsDetails = await getNewsDetails(newsId);
      setNews({
        message: fetchedNewsDetails.message,
        attachment1: fetchedNewsDetails.attachment1,
        attachment2: fetchedNewsDetails.attachment2,
        author: fetchedNewsDetails.author,
        groups_names: fetchedNewsDetails.groups_names
      });

      setComments(fetchedNewsDetails.comments);
    } catch (err) {}
  };

  useEffect(() => {
    _fetchNewsDetails();
  }, []);

  if (!news) {
    return null;
  }

  const onCommentSave = async (value) => {
    const response = await addComment(newsId, { content: value });
    setComments((prev) => [response, ...prev]);
  };

  const onCommentRemove = async (comment_id) => {
    await removeComment(comment_id);
    setComments((prev) =>
      prev.filter((item) => item.comment_id !== comment_id),
    );
  };

  const onCommentEditSave = async (comment) => {
    await editComment(newsId, comment.comment_id, { content: comment.content });

    setComments((prev) =>
      prev.map((item) => {
        if (item.comment_id === comment.comment_id) {
          return {
            ...item,
            content: comment.content,
          };
        } else {
          return item;
        }
      }),
    );

    hideModal();
  };

  const showEditCommentModal = (comment) => {
    showModal(CommentEditModal, {
      comment,
      onSave: onCommentEditSave,
    });
  };

  return (
    <Container>
      <div className="news__single">
        <div dangerouslySetInnerHTML={{ __html: news.message }} />

        {news.attachment1 && (
          <button
            className="news__attachment"
            onClick={() => downloadFile(news.attachment1)}
          >
            <FontAwesomeIcon icon={faFile} />
            <span>{news.attachment1}</span>
          </button>
        )}
        {news.attachment2 && (
          <button
            className="news__attachment"
            onClick={() => downloadFile(news.attachment2)}
          >
            <FontAwesomeIcon icon={faFile} />
            <span>{news.attachment2}</span>
          </button>
        )}
        <div className="news__groupDetails">
          <strong>Grupy:</strong> {news.groups_names}
        </div>
        <div className="news__metaDetails">
          <span>
            <strong>Autor</strong>: {news.author}
          </span>
        </div>
      </div>

      {!user.muted && (
        <>
          <h3>Dodaj komentarz:</h3>

          <CommentInput onCommentSave={onCommentSave} />
        </>
      )}
      <h2>Komentarze ({comments?.length}):</h2>
      <hr />
      {comments?.length ? (
        <>
          <Container>
            {comments.map((comment) => (
              <div
                key={comment.comment_id}
                id={comment.comment_id}
                className="news__comment"
              >
                <span className="news__comment-author">
                  {comment.author_name}
                </span>
                <div
                  className="news__comment-content"
                  dangerouslySetInnerHTML={{ __html: comment.content }}
                />
                <p className="news__comment-date">
                  Data dodania: {new Date(comment.created_at).toLocaleString()}
                </p>
                {comment?.modified_at && (
                  <p className="news__comment-date">
                    Data modyfikacji:{" "}
                    {new Date(comment.modified_at).toLocaleString()}
                  </p>
                )}

                {(checkCommentAuthor(comment.author_id) || isManagement) && (
                  <button
                    className="news__comment-action-button"
                    onClick={() => showEditCommentModal(comment)}
                  >
                    Edytuj
                  </button>
                )}
                {isManagement && (
                  <button
                    className="news__comment-action-button"
                    onClick={() => onCommentRemove(comment.comment_id)}
                  >
                    Usuń
                  </button>
                )}
              </div>
            ))}
          </Container>
        </>
      ) : (
        <span>Brak komentarzy</span>
      )}
    </Container>
  );
};

export default withRouter(News);
