import Box from "@mui/material/Box";
import React, { useContext, useEffect, useState } from "react";
import Container from "../../components/Container";
import {
  getSubjectUsers,
  unAssignGroupFromSubject,
  unAssignUserFromSubject,
} from "../../api/subjectClient";
import { withRouter } from "react-router-dom";
import SubjectUsersAddListControls from "./SubjectStudentsListControls";
import SubjectGroupsAddListControls from "./SubjectGroupsListControls";
import { UserContext } from "../../contexts/UserContextProvider";
import {SubjectGroupsColumnType, SubjectUsersColumnType} from "../../models/TableModel";
import {TableContainer, TableBody, TableCell, TableHead, TableRow, Table} from "@mui/material";
import ButtonMUI from "@mui/material/Button";

const USERS_TABLE_COLUMNS: readonly SubjectUsersColumnType[] = [
  { id: "first_name", label: "Imię" },
  { id: "last_name", label: "Nazwisko" },
  { id: "email", label: "E-mail" },
  {
    id: "actions",
    label: "",
    align: "right",
  },
];
const GROUPS_TABLE_COLUMNS: readonly SubjectGroupsColumnType[] = [
  { id: "name", label: "Nazwa grupy" },
  { id: "code", label: "Kod grupy" },
  {
    id: "actions",
    label: "",
    align: "right",
  },
];
const SubjectUsersList = ({ history }) => {
  const subjectId = history.location.pathname.split("/")[2];
  const [details, setDetails] = useState(null);
  const { isManagement, isMentor } = useContext(UserContext);

  const _fetchSubjectUsers = async () => {
    const response = await getSubjectUsers(subjectId);
    setDetails(response);
  };
  useEffect(() => {
    _fetchSubjectUsers();
  }, []);


  const updateUser = (user, isTeacher = false) => {
    setDetails({
      ...details,
      count: {
        ...details.count,
        [isTeacher ? "teachers" : "students"]:
          details.count[isTeacher ? "teachers" : "students"] + 1,
      },
      not_assigned: {
        ...details.not_assigned,
        [isTeacher ? "teachers" : "extra_students"]: [
          ...details.not_assigned[
            isTeacher ? "teachers" : "extra_students"
          ].filter((u) => u.user_id !== user.user_id),
        ],
      },
      assigned: {
        ...details.assigned,
        [isTeacher ? "teachers" : "extra_students"]: [
          ...details.assigned[isTeacher ? "teachers" : "extra_students"],
          user,
        ],
      },
    });
  };

  const updateGroup = (group) => {
    setDetails({
      ...details,
      count: {
        ...details.count,
        groups: details.count.groups + 1,
      },
      not_assigned: {
        ...details.not_assigned,
        groups: [
          ...details.not_assigned.groups.filter(
            (g) => g.group_id !== group.group_id,
          ),
        ],
      },
      assigned: {
        ...details.assigned,
        groups: [...details.assigned.groups, group],
      },
    });
  };

  const unAssignGroup = (group) => {
    unAssignGroupFromSubject(subjectId, group.group_id).then((_) => {
      const filteredUsers = details.assigned.groups.filter(
        (g) => g.group_id !== group.group_id,
      );
      setDetails({
        ...details,
        count: {
          ...details.count,
          groups: details.count.groups - 1,
        },
        not_assigned: {
          ...details.not_assigned,
          groups: [...details.not_assigned.groups, group],
        },
        assigned: {
          ...details.assigned,
          groups: [...filteredUsers],
        },
      });
    });
  };

  const unAssignUser = (user, isTeacher = false) => {
    unAssignUserFromSubject(subjectId, user.user_id).then((_) => {
      const filteredUsers = details.assigned[
        isTeacher ? "teachers" : "extra_students"
      ].filter((student) => student.user_id !== user.user_id);
      setDetails({
        ...details,
        count: {
          ...details.count,
          [isTeacher ? "teachers" : "students"]:
            details.count[isTeacher ? "teachers" : "students"] - 1,
        },
        not_assigned: {
          ...details.not_assigned,
          [isTeacher ? "teachers" : "extra_students"]: [
            ...details.not_assigned[isTeacher ? "teachers" : "extra_students"],
            user,
          ],
        },
        assigned: {
          ...details.assigned,
          [isTeacher ? "teachers" : "extra_students"]: [...filteredUsers],
        },
      });
    });
  };

  const goToGoals = (userId) =>
    history.push(`/uzytkownicy/${userId}/cele/${subjectId}`);
  const goToGroup = (groupId) =>
    history.push(`/uzytkownicy/grupy/${groupId}?subject=${subjectId}`);

  if (!details) return null;

  return (
    <Container>
      <h1>
        {details.name} ({details.code})
      </h1>

      <Box marginBottom="32px" marginTop="32px">
        <Box marginBottom="8px" display="flex" justifyContent="space-between">
          <h3>Nauczyciele ({details.count.teachers})</h3>
          {isManagement && (
            <SubjectUsersAddListControls
              subjectId={subjectId}
              usersNotAssigned={details.not_assigned.teachers}
              is_teacher={true}
              onSave={(user) => updateUser(user, true)}
            />
          )}
        </Box>

        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {USERS_TABLE_COLUMNS.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{
                          backgroundColor: "#004e8d",
                          color: "#fff",
                        }}
                    >
                      {column.label}
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {details.assigned.teachers.map((teacher) => (
                  <TableRow hover tabIndex={-1} key={teacher.email}>
                    <TableCell>{teacher.first_name}</TableCell>
                    <TableCell>{teacher.last_name}</TableCell>
                    <TableCell>{teacher.email}</TableCell>
                   <TableCell align="right">
                     {isManagement && !isMentor && (
                         <ButtonMUI
                             variant="outlined"
                             size="small"
                             color="error"
                             sx={{marginRight: '8px'}}
                             onClick={() => unAssignUser(teacher, true)}
                         >
                           Usuń
                         </ButtonMUI>
                     )}
                   </TableCell>
                  </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box marginBottom="32px" marginTop="32px">
        <Box marginBottom="8px" display="flex" justifyContent="space-between">
          <h3>Grupy ({details.count.groups})</h3>
          {isManagement && (
            <SubjectGroupsAddListControls
              subjectId={subjectId}
              notAssigned={details.not_assigned.groups}
              onSave={(group) => updateGroup(group)}
            />
          )}
        </Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {GROUPS_TABLE_COLUMNS.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{
                          backgroundColor: "#004e8d",
                          color: "#fff",
                        }}
                    >
                      {column.label}
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {details.assigned.groups.map((group) => (
                  <TableRow key={group.group_id}>
                    <TableCell>{group.name}</TableCell>
                    <TableCell>{group.code}</TableCell>
                    <TableCell align="right">
                      <ButtonMUI
                          variant="outlined"
                          size="small"
                          sx={{marginRight: '8px'}}
                          onClick={() => goToGroup(group.group_id)}
                      >
                        Zarządzaj
                      </ButtonMUI>
                      {isManagement && !isMentor && (
                          <ButtonMUI
                              variant="outlined"
                              size="small"
                              color="error"
                              sx={{marginRight: '8px'}}
                              onClick={() => unAssignGroup(group)}
                          >
                            Usuń
                          </ButtonMUI>
                      )}
                    </TableCell>
                  </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box marginBottom="32px" marginTop="32px">
        <Box marginBottom="8px" display="flex" justifyContent="space-between">
          <h3>Uczniowie dodatkowi ({details.count.students})</h3>
          {isManagement && (
            <SubjectUsersAddListControls
              subjectId={subjectId}
              usersNotAssigned={details.not_assigned.extra_students}
              is_teacher={false}
              onSave={(user) => updateUser(user, false)}
            />
          )}
        </Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {USERS_TABLE_COLUMNS.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{
                          backgroundColor: "#004e8d",
                          color: "#fff",
                        }}
                    >
                      {column.label}
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {details.assigned.extra_students.map((student) => (
                  <TableRow hover tabIndex={-1} key={student.email}>
                    <TableCell>{student.first_name}</TableCell>
                    <TableCell>{student.last_name}</TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell align="right">
                      <ButtonMUI
                          variant="outlined"
                          size="small"
                          sx={{marginRight: '8px'}}
                          onClick={() => goToGoals(student.user_id)}
                      >
                        Cele
                      </ButtonMUI>
                      {isManagement && !isMentor && (
                          <ButtonMUI
                              variant="outlined"
                              size="small"
                              color="error"
                              sx={{marginRight: '8px'}}
                              onClick={() => unAssignUser(student, false)}
                          >
                            Usuń
                          </ButtonMUI>
                      )}
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default withRouter(SubjectUsersList);
