import AuthContainer from "./AuthContainer";
import Card from "@mui/material/Card";
import logo from "../../assets/logo.svg";
import styles from "./Login.module.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import { passwordReset } from "../../api/authClient";
import { withRouter } from "react-router-dom";

const PasswordReset = ({ history }) => {
  const [email, setEmail] = useState("");

  const handleReset = () => {
    if (email) {
      try {
        passwordReset(email).then(() => {
          history.push("/logowanie?tokenSent");
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <AuthContainer>
      <Card color="white">
        <div className={styles.logoHandler}>
          <img alt="Logo IDER" src={logo} className={styles.logo} />
        </div>
        <Box display="flex" flexDirection="column" padding="16px">
          <Box marginBottom="16px">
            <TextField
              variant="outlined"
              id="email"
              label="E-mail"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Box marginBottom="16px" width="100%">
            <Button variant="outlined" color="primary" onClick={handleReset}>
              Resetuj
            </Button>
          </Box>
        </Box>
      </Card>
    </AuthContainer>
  );
};

export default withRouter(PasswordReset);
