import { API_URL } from "../constants";

export const getSubjectCategories = async () => {
  try {
    const response = await global.fetch(`${API_URL}/categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const saveCategory = async (category) => {
  try {
    const response = await global.fetch(`${API_URL}/categories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(category),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const updateCategory = async (categoryId, categoryDetails) => {
  try {
    const response = await global.fetch(`${API_URL}/categories/${categoryId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(categoryDetails),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const deleteCategory = async (categoryId) => {
  try {
    await global.fetch(`${API_URL}/categories/${categoryId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
};
