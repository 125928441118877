import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { GroupContext } from "../../contexts/GroupContext";

import "./UserNavigation.scss";

export const UsersNavigation = ({ subjectId }) => {
  const history = useHistory();
  const {
    students,
    setStudents,
    studentsLength,
    currentEditingIndex,
    setCurrentEditingIndex,
  } = useContext(GroupContext);

  useEffect(() => {
    return () => {
      setStudents([]);
    };
  }, []);

  const goToUser = (index) => {
    setCurrentEditingIndex(index);
    history.push(`/uzytkownicy/${students[index].user_id}/cele/${subjectId}`);
  };

  return (
    <div className="navigation__handler">
      <div>
        {currentEditingIndex !== 0 && (
          <button
            className="navigation__button"
            onClick={() => goToUser(currentEditingIndex - 1)}
          >
            <FontAwesomeIcon icon={faChevronLeft} cursor="pointer" size="2x" />
            <span className="navigation--withLeftMargin">Poprzedni uczeń</span>
          </button>
        )}
      </div>
      <div>
        {currentEditingIndex !== studentsLength - 1 && (
          <button
            className="navigation__button"
            onClick={() => goToUser(currentEditingIndex + 1)}
          >
            <span className="navigation--withRightMargin">Następny uczeń</span>
            <FontAwesomeIcon icon={faChevronRight} cursor="pointer" size="2x" />
          </button>
        )}
      </div>
    </div>
  );
};
