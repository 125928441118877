import { API_URL } from "../constants";

export const getSubjects = async (userId = "", rel = null) => {
  const customHeaders: { trimesterId?: string } = {};
  if (window.sessionStorage.getItem("trimesterId")) {
    customHeaders["X-Active-Trimester"] =
      window.sessionStorage.getItem("trimesterId");
  }
  try {
    const response = await global.fetch(
      `${API_URL}/subjects${userId ? "?userId=" + userId : ""}${rel ? `&rel=${rel}` : '' }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...customHeaders,
        },
        credentials: "include",
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getSubjectsFromTrimester = async (trimesters) => {
  try {
    const response = await global.fetch(
      `${API_URL}/subjects/from-trimesters?trimesters=${trimesters}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getSubjectUsers = async (subjectId) => {
  try {
    const response = await global.fetch(`${API_URL}/subjects/${subjectId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getSubjectStudents = async (subjectId) => {
  try {
    const response = await global.fetch(
      `${API_URL}/subjects/${subjectId}/students`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getStudentsNotAssigned = async (subjectId) => {
  try {
    const response = await global.fetch(
      `${API_URL}/subjects/${subjectId}/students-not-assigned`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const assignUserToSubject = async (details) => {
  try {
    await global.fetch(
      `${API_URL}/subjects/${details.subject_id}/users/assign`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(details),
      },
    );
  } catch (e) {
    console.log(e);
  }
};

export const assignGrade = async (subjectId, userId, grade) => {
    try {
        await global.fetch(
            `${API_URL}/subjects/${subjectId}/assign-grade`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({user_id: userId, ...grade}),
            },
        );
    } catch (e) {
        console.log(e);
    }
};

export const unAssignUserFromSubject = async (subjectId, userId) => {
  try {
    await global.fetch(
      `${API_URL}/subjects/${subjectId}/users/${userId}/unassign`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );
  } catch (e) {
    console.log(e);
  }
};

export const saveSubject = async (subject) => {
  try {
    const response = await global.fetch(`${API_URL}/subjects`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(subject),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const updateSubjectApi = async (subjectId, subject) => {
  try {
    const response = await global.fetch(`${API_URL}/subjects/${subjectId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(subject),
    });
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const deleteSubject = async (subjectId) => {
  try {
    await global.fetch(`${API_URL}/subjects/${subjectId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
};

export const assignGroupToSubject = async (details) => {
  try {
    await global.fetch(
      `${API_URL}/subjects/${details.subject_id}/groups/assign`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(details),
      },
    );
  } catch (e) {
    console.log(e);
  }
};

export const unAssignGroupFromSubject = async (subjectId, groupId) => {
  try {
    await global.fetch(
      `${API_URL}/subjects/${subjectId}/groups/${groupId}/unassign`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );
  } catch (e) {
    console.log(e);
  }
};
