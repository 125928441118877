import { UserRole } from "../constants";

const getRoleColor = (role) => {
    switch (role) {
        case UserRole.ROOT:
            return "#bc0000";
        case UserRole.ADMIN:
            return "#bc0000";
        case UserRole.MENTOR:
            return "#ffa600";
        case UserRole.TEACHER:
            return "#169202";
        case UserRole.STUDENT:
            return '#008394';
        case UserRole.ALUMNI:
            return '#6100bc';
    }
};

export default getRoleColor;
