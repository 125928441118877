import React, { useRef, useState } from "react";
import { Message } from "../../@types/enums/Message";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

import "./FileInput.scss";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FileInput = ({ accept, onUpload, onDelete = () => {}, multiple = false }) => {
  const ref = useRef<HTMLInputElement>();
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const MAX_FILE_SIZE = 5; // MB

  const handleFileUpload = (event) => {
    setErrorMessage(null);
    const files = event.target.files;
    if (files.length) {
      if (
        Array.from(files).find(
          (file: any) => file.size / 1024 / 1024 > MAX_FILE_SIZE,
        )
      ) {
        setErrorMessage({
          type: Message.ERROR,
          text: "Plik jest za duży. Maksymalny rozmiar to 5 MB.",
        });
      } else if (files.length > 2) {
        setErrorMessage({
          type: Message.ERROR,
          text: "Można przesłać maksymalnie dwa pliki.",
        });
      } else {
        setFiles(multiple ? files : [files[0]]);
        onUpload(multiple ? files : [files[0]]);
      }
    }
  };

  const clearFile = (index) => {
    const node = ref.current;
    node.value = "";

    onDelete();

    const filteredFiles = files.filter((_, idx) => idx !== index);
    setFiles(filteredFiles);
    onUpload(filteredFiles);
  };

  return (
    <>
      <div className="fileInput__handler">
        {errorMessage && <ErrorMessage message={errorMessage.text} />}
        {!files.length && (
          <label htmlFor="file-upload" className="fileInput__button">
            {multiple ? "Dołącz pliki" : "Dołącz plik"}
          </label>
        )}
        {Array.from(files)?.map((file: any, index) => (
          <div>
            <span className="fileInput__filename">{file.name}</span>
            <FontAwesomeIcon
              icon={faTrash}
              cursor="pointer"
              size="lg"
              onClick={() => clearFile(index)}
            />
          </div>
        ))}

        <input
          ref={ref}
          id="file-upload"
          type="file"
          multiple={multiple}
          accept={accept}
          onChange={handleFileUpload}
        />
      </div>
    </>
  );
};
