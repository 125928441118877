import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

import Container from "../../components/Container";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import {
  activateSchoolYear,
  getSchoolYears,
} from "../../api/schoolYearsClient";

import "./SchoolYears.css";
import { ModalContext } from "../../contexts/ModalContext";
import { SchoolYearForm } from "./SchoolYearForm";
import { activateTrimester } from "../../api/trimestersClient";
import ConfirmationModal from "../../components/ConfirmationModal";
import { ServerMessage } from "../../components/ServerMessage/ServerMessage";

const SchoolYears = () => {
  const [schoolYears, setSchoolYears] = useState([]);
  const [serverMessage, setServerMessage] = useState(null);
  const { showModal } = useContext(ModalContext);

  const fetchSchoolYears = async () => {
    try {
      const response = await getSchoolYears();
      setSchoolYears(response.years);
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message: "Nie udało się pobrać danych",
      });
    }
  };

  useEffect(() => {
    fetchSchoolYears();
  }, []);

  const updateSchoolYears = (newYear) => {
    setServerMessage({
      type: "SUCCESS",
      message: "Zaktualizowano dane roku szkolnego",
    });
    setSchoolYears((oldState) => [newYear, ...oldState]);
  };

  const showForm = () => showModal(SchoolYearForm, { updateSchoolYears });

  if (!schoolYears) {
    return null;
  }

  const handleTrimesterActivate = async (trimesterId) => {
    try {
      await activateTrimester(trimesterId).then((_) => {
        setSchoolYears((years) =>
          years.map((year) => ({
            ...year,
            trimesters: year.trimesters.map((trimester) => ({
              ...trimester,
              active: trimester.trimester_id === trimesterId,
            })),
          })),
        );
        setServerMessage({
          type: "SUCCESS",
          message: "Trymestr został aktywowany.",
        });
      });
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message:
          "Nie udało się aktywować trymestru. Spróbuj ponownie za chwilę.",
      });
    }
  };

  const showTrimesterActivateModal = (trimesterId) => {
    showModal(ConfirmationModal, {
      action: () => handleTrimesterActivate(trimesterId),
      text: "Czy na pewno chcesz aktywować trymestr? Operacja spowoduje deaktywacje aktywnego trymestru i przeładowanie danych",
    });
  };

  const handleYearActivate = async (yearId) => {
    try {
      await activateSchoolYear(yearId).then((_) => {
        setSchoolYears((years) =>
          years.map((year) => ({
            ...year,
            active: yearId === year.year_id,
            trimesters: year.trimesters.map((trimester) => {
              return yearId !== year.year_id
                ? { ...trimester, active: false }
                : { ...trimester, active: trimester.code.includes("_1") };
            }),
          })),
        );
        setServerMessage({
          type: "SUCCESS",
          message: "Rok szkolny został aktywowany.",
        });
      });
    } catch (error) {
      setServerMessage({
        type: "ERROR",
        message:
          "Nie udało się aktywować roku szkolnego. Spróbuj ponownie za chwilę.",
      });
    }
  };

  const showYearActivateModal = (yearId) => {
    showModal(ConfirmationModal, {
      action: () => handleYearActivate(yearId),
      text: "Czy na pewno chcesz aktywować rok szkolny? Operacja spowoduje przeładowanie danych",
    });
  };

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1>Rok szkolny</h1>
        <Box display="flex">
          <Button withRightMargin onClick={showForm} type="primary">
            Dodaj
          </Button>
        </Box>
      </Box>

      <ServerMessage messageObj={serverMessage} />

      {schoolYears.map((year) => (
        <div className="yearsHandler">
          <div className="yearsHeading">
            <h2>
              {year.year_started_at} / {year.year_ended_at}
            </h2>
            {year.active && (
              <span className="active marginLeft8px">Aktywny</span>
            )}
            {!year.active && (
              <FontAwesomeIcon
                onClick={() => showYearActivateModal(year.year_id)}
                icon={faPlay}
                cursor="pointer"
                size="lg"
                color="green"
                className="marginLeft8px"
              />
            )}
          </div>
          {year.trimesters.map((trimester) => (
            <div className="trimesterHandler">
              <span>{trimester.code}</span>
              {!trimester.active && year.active && (
                <FontAwesomeIcon
                  onClick={() =>
                    showTrimesterActivateModal(trimester.trimester_id)
                  }
                  icon={faPlay}
                  cursor="pointer"
                  size="xs"
                  color="green"
                  className="marginLeft8px"
                />
              )}
              {trimester.active && (
                <span className="active marginLeft8px">Aktywny</span>
              )}
            </div>
          ))}
        </div>
      ))}
    </Container>
  );
};

export default SchoolYears;
