import React, { useContext } from "react";

import Box from "@mui/material/Box";

import {Link, withRouter} from "react-router-dom";
import { UserContext } from "../../../contexts/UserContextProvider";
import getRoleNicename from "../../../helpers/getRoleNicename";

import "./Profile.scss";
import Avatar from "@mui/material/Avatar";

const Profile = ({ history }) => {
  const { user } = useContext(UserContext);

  return (
      <Link to={`/profil/${user.user_id}`}>

    <div className="profile">
      <Box mr="8px">
        <Avatar
            alt={`${user.first_name} ${user.last_name}`}
            src={user?.avatar_url ?`/avatars/${user.avatar_url}` : undefined}
        />
      </Box>
      <div className="profile__user">
        <span className="profile__userName">
          {user.first_name} {user.last_name}
        </span>
        <span className="profile__role">{getRoleNicename(user.role)}</span>
      </div>
    </div>
      </Link>

  );
};

export default withRouter(Profile);
