import React, { useContext, useEffect, useState } from "react";
import { deleteGroup, getGroups } from "../../api/userGroupsClient";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import Container from "../../components/Container";
import { ModalContext } from "../../contexts/ModalContext";
import GroupForm from "./GroupForm";
import ConfirmationModal from "../../components/ConfirmationModal";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../contexts/UserContextProvider";
import GroupMessage from "./GroupMessage";
import { ServerMessage } from "../../components/ServerMessage/ServerMessage";
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import {UserGroupsColumnType} from "../../models/TableModel";
import ButtonMUI from "@mui/material/Button";

const TABLE_COLUMNS: readonly UserGroupsColumnType[] = [
  { id: "name", label: "Nazwa grupy" },
  { id: "code", label: "Kod grupy" },
  {
    id: "actions",
    label: "",
    align: "right",
  },
];

const UserGroups = ({ history }) => {
  const { showModal } = useContext(ModalContext);
  const { isManagement, isRoot, isAdmin, isTeacher } = useContext(UserContext);
  const [groups, setGroups] = useState([]);
  const [serverMessage, setServerMessage] = useState(null);


  const _fetchGroups = async () => {
    try {
      const fetchedGroups = await getGroups();
      setGroups(fetchedGroups.groups);
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message: "Nie udało się pobrać grup.",
      });
    }
  };

  const updateUserGroups = (newGroup, groupId = null) => {
    if (groupId) {
      setGroups((g) =>
        g.map((group) =>
          group.group_id === newGroup.group_id
            ? { ...group, ...newGroup }
            : group,
        ),
      );
      return;
    }
    setGroups([
      ...groups,
      {
        group_id: newGroup.group_id,
        name: newGroup.name,
        code: newGroup.code,
      },
    ]);
    return;
  };

  const showGroupMessageModal = (groupId) => {
    showModal(GroupMessage, {
      groupId,
    });
  };

  const showGroupsModal = (group = null) => {
    setServerMessage(null);
    showModal(GroupForm, {
      updateUserGroups,
      setServerMessage,
      ...(group ? { groupDetails: group } : {}),
    });
  };

  const handleGroupDelete = async (groupId) => {
    try {
      await deleteGroup(groupId).then((_) => {
        setGroups((g) => g.filter((group) => group.group_id !== groupId));
      });

      setServerMessage({
        type: "SUCCESS",
        message: "Pomyślnie usunięto grupę.",
      });
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message: "Nie udało się usunąć grupy. Spróbuj ponownie za chwilę.",
      });
    }
  };

  const showDeleteModal = (groupId) => {
    showModal(ConfirmationModal, {
      action: () => handleGroupDelete(groupId),
      text: "Czy na pewno chcesz usunąć grupę? Operacja jest nieodwracalna",
    });
  };

  const redirectToManageUsers = (groupId) => {
    history.push(`/uzytkownicy/grupy/${groupId}`);
  };

  useEffect(() => {
    _fetchGroups();
  }, []);

  if (!groups) {
    return null;
  }

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1>Grupy</h1>
        {isManagement && (
          <Box>
            <Button onClick={showGroupsModal} type="primary">
              Dodaj
            </Button>
          </Box>
        )}
      </Box>
      <ServerMessage messageObj={serverMessage} />

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {TABLE_COLUMNS.map((column) => (
                  <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        backgroundColor: "#004e8d",
                        color: "#fff",
                      }}
                  >
                    {column.label}
                  </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map(group => (
                <TableRow hover tabIndex={-1} key={group.group_id}>
                  <TableCell>{group.name}</TableCell>
                  <TableCell>{group.code}</TableCell>
                  <TableCell align="right">
                    <ButtonMUI
                        variant="outlined"
                        size="small"
                        sx={{marginRight: '8px'}}
                        onClick={() => redirectToManageUsers(group.group_id)}
                    >
                      Zarządzaj
                    </ButtonMUI>
                    {(isManagement || isTeacher) && (<ButtonMUI
                        variant="outlined"
                        size="small"
                        sx={{marginRight: '8px'}}
                        onClick={() => showGroupMessageModal(group.group_id)}
                    >
                      Wiadomość
                    </ButtonMUI>)}
                    {isManagement && (<ButtonMUI
                        variant="outlined"
                        size="small"
                        sx={{marginRight: '8px'}}
                        onClick={() => showGroupsModal(group)}
                    >
                      Edytuj
                    </ButtonMUI>)}
                    {(isRoot || isAdmin) && (<ButtonMUI
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => showDeleteModal(group.group_id)}
                    >
                      Usuń
                    </ButtonMUI>)}
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default withRouter(UserGroups);
