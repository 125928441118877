import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "../../components/Button/Button";
import React, { useContext, useEffect, useState } from "react";
import { saveSubject, updateSubjectApi } from "../../api/subjectClient";
import { getTrimesters } from "../../api/trimestersClient";
import { ModalContext } from "../../contexts/ModalContext";
import { getSubjectCategories } from "../../api/categoriesClient";

const SubjectForm = ({
  subjectDetails = null,
  updateSubject,
  setServerMessage,
}) => {
  const [subject, setSubject] = useState({
    name: subjectDetails?.name || "",
    code: subjectDetails?.code || "",
    subject_cat_id: subjectDetails?.subject_cat_id || null,
    trimester_id: subjectDetails?.trimester_id || null,
  });
  const [categories, setCategories] = useState([]);
  const [trimesters, setTrimesters] = useState([]);
  const { hideModal } = useContext(ModalContext);

  const _fetchCategories = async () => {
    const fetchedCategories = await getSubjectCategories();
    setCategories(fetchedCategories.categories);
  };

  const _fetchTrimesters = async () => {
    const fetchedTrimesters = await getTrimesters();
    setTrimesters(fetchedTrimesters.trimesters);
  };

  const handleSave = async () => {
    if (subjectDetails) {
      try {
        const response = updateSubjectApi(subjectDetails.subject_id, {
          ...subjectDetails,
          ...subject,
        });
        setServerMessage({
          type: "SUCCESS",
          message: "Pomyślnie zaktualizowano przedmiot.",
        });
        updateSubject({ ...response }, subjectDetails?.subject_id);
      } catch (err) {
        setServerMessage({
          type: "ERROR",
          message: "Nie udało się zaktualizować przedmiotu.",
        });
      }
    } else {
      try {
        const response = await saveSubject({ ...subject });
        setServerMessage({
          type: "SUCCESS",
          message: "Pomyślnie dodano przedmiot.",
        });
        updateSubject({ ...response }, null);
      } catch (err) {
        setServerMessage({
          type: "ERROR",
          message: "Nie udało się dodać przedmiotu.",
        });
      }
    }

    hideModal();
  };

  const handleInputChange = (event) =>
    setSubject({ ...subject, [event.target.id]: event.target.value });

  const handleTrimesterChange = (event) =>
    setSubject({ ...subject, trimester_id: event.target.value });

  const handleCategoryChange = (event) =>
    setSubject({ ...subject, subject_cat_id: event.target.value });

  useEffect(() => {
    _fetchCategories();
    _fetchTrimesters();
  }, []);

  return (
    <React.Fragment>
      <h1>Dodaj przedmiot</h1>
      <Box marginBottom="16px">
        <TextField
          variant="outlined"
          id="name"
          label="Nazwa przedmiotu"
          fullWidth
          onChange={handleInputChange}
          value={subject?.name}
        />
      </Box>
      <Box marginBottom="16px">
        <TextField
          variant="outlined"
          id="code"
          label="Kod przedmiotu"
          fullWidth
          onChange={handleInputChange}
          value={subject?.code}
        />
      </Box>
      <Box marginBottom="16px">
        <select value={subject.subject_cat_id} onChange={handleCategoryChange}>
          <option selected value={null}>
            Wybierz
          </option>
          {categories.map((category) => (
            <option value={category.subject_cat_id}>{category.name}</option>
          ))}
        </select>
      </Box>
      <Box marginBottom="16px">
        <select value={subject.trimester_id} onChange={handleTrimesterChange}>
          <option selected value={null}>
            Wybierz
          </option>
          {trimesters.map((trimester) => (
            <option value={trimester.trimester_id}>{trimester.code}</option>
          ))}
        </select>
      </Box>
      <Box marginBottom="16px" width="100%">
        <Button onClick={handleSave} type="primary">
          Wyślij
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default SubjectForm;
