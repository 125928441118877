import Container from "../../components/Container";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  getGroupDetails,
  unassignUserFromGroup,
} from "../../api/userGroupsClient";

import AddUserToGroupControls from "./AddUserToGroupControls";
import { UserContext } from "../../contexts/UserContextProvider";
import { GroupContext } from "../../contexts/GroupContext";
import { ServerMessage } from "../../components/ServerMessage/ServerMessage";
import {UserGroupDetailsColumnType} from "../../models/TableModel";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import ButtonMUI from "@mui/material/Button";

const TABLE_COLUMNS: readonly UserGroupDetailsColumnType[] = [
  { id: "first_name", label: "Imię" },
  { id: "last_name", label: "Nazwisko" },
  { id: "email", label: "E-mail" },
  {
    id: "actions",
    label: "",
    align: "right",
  },
];
const UserGroupList = ({ history }) => {
  const groupId = history.location.pathname.split("/")[3];
  const subjectObject = history.location.search.replace("?", "").split("=");
  const [details, setDetails] = useState(null);
  const [serverMessage, setServerMessage] = useState(null);

  const { isManagement } = useContext(UserContext);
  const { students, setStudents } = useContext(GroupContext);

  const _fetchGroupDetails = async () => {
    try {
      const response = await getGroupDetails(groupId);
      setStudents(response.assigned);
      setDetails(response);
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message: "Nie udało się pobrać szczegółów grupy.",
      });
    }
  };
  useEffect(() => {
    _fetchGroupDetails();
  }, []);

  const updateUser = (user) => {
    setStudents([...details.assigned, user]);
    setDetails({
      ...details,
      not_assigned: [
        ...details.not_assigned.filter((u) => u.user_id !== user.user_id),
      ],
      assigned: [...details.assigned, user],
    });

    setServerMessage({
      type: "SUCCESS",
      message: "Pomyślnie zapisano dane.",
    });
  };

  const deleteUserFromGroup = (user) => {
    try {
      unassignUserFromGroup(groupId, user.user_id).then((_) => {
        const filteredUsers = details.assigned.filter(
          (u) => u.user_id !== user.user_id,
        );
        setStudents(filteredUsers);
        setDetails({
          ...details,
          not_assigned: [...details.not_assigned, user],
          assigned: [...filteredUsers],
        });
        setServerMessage({
          type: "SUCCESS",
          message: "Pomyślnie odpięto użytkownika od grupy.",
        });
      });
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message: "Nie udało się odpiąć użytkownika od grupy.",
      });
    }
  };

  const goToGoals = (userId) => {
    if (subjectObject[1]) {
      history.push(`/uzytkownicy/${userId}/cele/${subjectObject[1]}`);
    }
  };

  if (!details) return null;

  return (
    <Container>
      <ServerMessage messageObj={serverMessage} />
      <h1>
        {details.name} ({details.code})
      </h1>
      {isManagement && (
        <AddUserToGroupControls
          groupId={groupId}
          usersNotAssigned={details.not_assigned}
          onSave={(user) => updateUser(user)}
        />
      )}
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {TABLE_COLUMNS.map((column) => (
                  <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        backgroundColor: "#004e8d",
                        color: "#fff",
                      }}
                  >
                    {column.label}
                  </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
              {details.assigned.map((assigned) => (
                  <TableRow hover tabIndex={-1} key={assigned.email}>
                    <TableCell>{assigned.first_name}</TableCell>
                    <TableCell>{assigned.last_name}</TableCell>
                    <TableCell>{assigned.email}</TableCell>
                    <TableCell align="right">
                      {subjectObject[0] === "subject" && subjectObject[1] && (<ButtonMUI
                          variant="outlined"
                          size="small"
                          sx={{marginRight: '8px'}}
                          onClick={() => goToGoals(assigned.user_id)}
                      >
                        Cele
                      </ButtonMUI>)}
                      {isManagement && (<ButtonMUI
                          variant="outlined"
                          size="small"
                          sx={{marginRight: '8px'}}
                          onClick={() => deleteUserFromGroup(assigned)}
                      >
                        Usuń
                      </ButtonMUI>)}
                    </TableCell>
                  </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default withRouter(UserGroupList);
