import { API_URL } from "../constants";

export const getGoals = async ({ subjectId, userId }) => {
  try {
    const response = await global.fetch(
      `${API_URL}/goals?subjectId=${subjectId}&userId=${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const saveGoals = async ({ subjectId, userId, goals }) => {
  try {
    const response = await global.fetch(
      `${API_URL}/goals?subjectId=${subjectId}&userId=${userId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(goals),
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const updateGoal = async (goal) => {
  try {
    const response = await global.fetch(`${API_URL}/goals/${goal.goal_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(goal),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const changeGoalVisibility = async (payload) => {
  try {
    const response = await global.fetch(
      `${API_URL}/goals/${payload.goal_id}/change-visibility`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ hidden: payload.hidden }),
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const deleteGoal = async (goalId) => {
  try {
    await global.fetch(`${API_URL}/goals/${goalId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
};
