import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "../../components/Container";
import { changePublishState, getSummaries } from "../../api/summariesClient";
import { useLocation } from "react-router-dom";
import Button from "../../components/Button/Button";
import { SummaryCard } from "./SummaryCard";
import Search from "./Search";

const SummaryList = () => {
  const [summaries, setSummaries] = useState([]);
  const [initialSummaries, setInitialSummaries] = useState([]);
  const [isInactiveVisible, setIsInactiveVisible] = useState(false);

  const location = useLocation();

  const _fetchSummaries = async (query = null) => {
    const response = await getSummaries(query);
    setSummaries(response.filter((summary) => !summary.published));
    setInitialSummaries(response);
    setIsInactiveVisible(false);
  };

  const togglePublish = async (summaryId, state) => {
    const response = await changePublishState({
      summary_id: summaryId,
      published: state,
    });

    setSummaries(
      summaries.map((summary) =>
        summary.summary_id === summaryId
          ? { ...summary, published: response.published }
          : summary,
      ),
    );
  };

  const changeSummariesVisibility = () => {
    setIsInactiveVisible(!isInactiveVisible);

    isInactiveVisible
      ? setSummaries(summaries.filter((summary) => !summary.published))
      : setSummaries(initialSummaries);
  };

  const updateSummary = (summaryId, description) =>
    setSummaries(
      summaries.map((summary) =>
        summaryId === summary.summary_id
          ? { ...summary, description }
          : summary,
      ),
    );

  useEffect(() => {
    const query = new URLSearchParams(location.search).get("query");
    _fetchSummaries(query);
  }, [location.search]);

  if (!summaries) {
    return null;
  }

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1>Podsumowania</h1>
        <Box display="flex">
          <Button onClick={() => changeSummariesVisibility()} type="secondary">
            {isInactiveVisible ? "Ukryj opublikowane" : "Pokaż opublikowane"}
          </Button>
        </Box>
      </Box>

      <Search />

      {summaries.map((summary) => (
        <SummaryCard
          key={summary.summary_id}
          summary={summary}
          updateSummary={updateSummary}
          togglePublish={togglePublish}
        />
      ))}
    </Container>
  );
};

export default SummaryList;
