import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import Container from "../../components/Container";
import { ModalContext } from "../../contexts/ModalContext";
import SubjectCategoryForm from "./SubjectCategoryForm";
import ConfirmationModal from "../../components/ConfirmationModal";
import {
  deleteCategory,
  getSubjectCategories,
} from "../../api/categoriesClient";
import { withRouter } from "react-router-dom";
import { ServerMessage } from "../../components/ServerMessage/ServerMessage";
import {TableContainer, TableHead, Table, TableBody, TableRow, TableCell} from "@mui/material";
import {SubjectCategoriesColumnType} from "../../models/TableModel";
import React, {useContext, useEffect, useState} from "react";
import Chip from "@mui/material/Chip";
import ButtonMUI from "@mui/material/Button";
import {UserContext} from "../../contexts/UserContextProvider";

const TABLE_COLUMNS: readonly SubjectCategoriesColumnType[] = [
  { id: "block_name", label: "Nazwa bloku" },
  { id: "creation_date", label: "Data utworzenia" },
  {
    id: "actions",
    label: "",
    align: "right",
  },
];

const SubjectCategoriesList = ({ history }) => {
  const [categories, setCategories] = useState([]);
  const { showModal } = useContext(ModalContext);
  const { isManagement } = useContext(UserContext);
  const [serverMessage, setServerMessage] = useState(null);

  const _fetchCategories = async () => {
    try {
      const fetchedCategories = await getSubjectCategories();
      setCategories(fetchedCategories.categories);
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message: "Nie udało się pobrać bloków przedmiotowych.",
      });
    }
  };

  const showDetailsModal = (category = null) =>
    showModal(SubjectCategoryForm, {
      updateSubjectCategory,
      setServerMessage,
      ...(category ? { categoryDetails: category } : {}),
    });

  const updateSubjectCategory = (newCategory, categoryId = null) => {
    if (categoryId) {
      setCategories((c) =>
        c.map((category) =>
          category.subject_cat_id === newCategory.subject_cat_id
            ? { ...category, ...newCategory }
            : category,
        ),
      );
      return;
    }
    setCategories([...categories, newCategory]);
    return;
  };

  const handleCategoryDelete = async (categoryId) => {
    try {
      await deleteCategory(categoryId).then((_) => {
        setCategories((c) =>
          c.filter((category) => category.subject_cat_id !== categoryId),
        );
        setServerMessage({
          type: "ERROR",
          message: "Pomyślnie usunięto blok przedmiotów.",
        });
      });
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message: "Nie udało się usunąć bloku przedmiotów.",
      });
    }
  };

  const showDeleteModal = (categoryId) => {
    showModal(ConfirmationModal, {
      action: () => handleCategoryDelete(categoryId),
      text: "Czy na pewno chcesz usunąć blok przedmiotu? Operacja jest nieodwracalna",
    });
  };

  useEffect(() => {
    _fetchCategories();
  }, []);

  if (!categories) {
    return null;
  }

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1>Bloki przedmiotowe</h1>
        <Box>
          <Button type="primary" onClick={showDetailsModal}>
            Dodaj
          </Button>
        </Box>
      </Box>
      <ServerMessage messageObj={serverMessage} />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {TABLE_COLUMNS.map((column) => (
                  <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        backgroundColor: "#004e8d",
                        color: "#fff",
                      }}
                  >
                    {column.label}
                  </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
                <TableRow hover tabIndex={-1} key={category.subject_cat_id}>
                  <TableCell>
                    <Chip label={category.name}  sx={{
                      backgroundColor: category.color,
                    }} />
                  </TableCell>
                  <TableCell>
                    {new Date(category.created_at).toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {isManagement && (<ButtonMUI
                        variant="outlined"
                        size="small"
                        sx={{marginRight: '8px'}}
                        onClick={() => showDetailsModal(category)}
                    >
                      Edytuj
                    </ButtonMUI>)}
                    {isManagement && (<ButtonMUI
                        variant="outlined"
                        size="small"
                        color="error"
                        sx={{marginRight: '8px'}}
                        onClick={() => showDeleteModal(category.subject_cat_id)}
                    >
                      Usuń
                    </ButtonMUI>)}
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default withRouter(SubjectCategoriesList);
