import React, { useEffect, useState } from "react";

import "./SubjectStudentsListControls.scss";
import { assignGroupToSubject } from "../../api/subjectClient";
import Select from "../../components/Select/Select";

const SubjectGroupsAddListControls = ({ subjectId, notAssigned, onSave }) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    setGroups(notAssigned);
  }, [notAssigned]);

  const assignGroup = (selectedGroupId) => {
    const dataToSend = {
      subject_id: subjectId,
      group_id: selectedGroupId,
    };
    assignGroupToSubject(dataToSend).then((_) => {
      const newGroups = groups.filter((g) => g.group_id !== selectedGroupId);
      onSave(groups.find((g) => g.group_id === selectedGroupId));
      setGroups(newGroups);
    });
  };

  const parseOptions = (groups): { value: string; label: string }[] =>
    groups.map((group) => ({
      value: group.group_id,
      label: `${group.name} (${group.code})`,
    }));

  return (
    <Select
      isButtonDisabled={groups.length === 0}
      options={parseOptions(groups)}
      method={assignGroup}
      noOptionsMessage="brak grup"
    />
  );
};

export default SubjectGroupsAddListControls;
