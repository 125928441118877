import { useCallback, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import debounce from "nanoutils/lib/debounce";

import "./Search.scss";
import FormControl from "@mui/material/FormControl";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

const Search = () => {
  const { push, replace } = useHistory();
  const location = useLocation();
  const [query, setQuery] = useState(
    new URLSearchParams(location.search).get("query") || "",
  );

  const handleChange = (value) => {
    setQuery(value);
    debounceChange(value);
  };

  const debounceChange = useCallback(
    debounce(600, (value) => {
      if (value.length > 3) {
        push(`?query=${value}`);
      } else {
        replace({
          search: "",
        });
      }
    }),
    [],
  );

  return (
    <FormControl fullWidth sx={{ marginBottom: "16px" }} variant="outlined">
      <InputLabel htmlFor="search">Wprowadź szukaną frazę...</InputLabel>
      <OutlinedInput
        id="search"
        type="text"
        defaultValue={query}
        onChange={(e) => handleChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        label="Wprowadź szukaną frazę..."
      />
    </FormControl>
  );
};

export default withRouter(Search);
