import { createContext, useEffect, useMemo, useState } from "react";
import { getTrimesters } from "../api/trimestersClient";

export const TrimestersContext = createContext({
  trimesters: [],
  initialTrimesters: [],
  setTrimesters: null,
  activeTrimesterId: null,
  setActiveTrimesterId: null,
  isLastTrimester: false
});

const TrimestersContextProvider = ({ children }) => {
  const [trimesters, setTrimesters] = useState([]);
  const [initialTrimesters, setInitialTrimesters] = useState([]);
  const [activeTrimesterId, setActiveTrimesterId] = useState(null);

  const _fetchTrimesters = async () => {
    const fetchedTrimesters = await getTrimesters();
    const sessionTrimesterId = window.sessionStorage.getItem("trimesterId");
    setTrimesters(fetchedTrimesters.trimesters.filter((t) => t.active));
    setInitialTrimesters(fetchedTrimesters.trimesters);
    setActiveTrimesterId(sessionTrimesterId);
  };

  useEffect(() => {
    _fetchTrimesters();
  }, []);

  const value = useMemo(
    () => ({
      trimesters,
      initialTrimesters,
      setTrimesters,
      activeTrimesterId,
      setActiveTrimesterId,
      isLastTrimester: trimesters.find(t => t.active)?.code.includes('_3') || false
    }),
    [trimesters, initialTrimesters, activeTrimesterId],
  );

  return (
    <TrimestersContext.Provider value={value}>
      {children}
    </TrimestersContext.Provider>
  );
};

export default TrimestersContextProvider;
