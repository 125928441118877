import "./SummaryCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";
import SummaryEditModal from "./SummaryEditModal";
import { ModalContext } from "../../contexts/ModalContext";
export const SummaryCard = ({ summary, updateSummary, togglePublish }) => {
  const { showModal } = useContext(ModalContext);

  const showEditSummaryModal = (summaryId, description) => {
    showModal(SummaryEditModal, {
      summaryId,
      description,
      updateSummary,
    });
  };

  return (
    <div className="summaryCard">
      <div className="summaryCard__heading">
        <span className="summaryCard__studentDetails">
          {summary.user.first_name} {summary.user.last_name}
        </span>
        <div className="summaryCard__controls">
          <FontAwesomeIcon
            onClick={() =>
              showEditSummaryModal(summary.summary_id, summary.description)
            }
            icon={faEdit}
            cursor="pointer"
            size="lg"
            className="iconWithMarginRight"
          />
          {summary.published ? (
            <FontAwesomeIcon
              onClick={() => togglePublish(summary.summary_id, false)}
              icon={faTimes}
              cursor="pointer"
              size="lg"
              className="iconWithMarginRight"
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => togglePublish(summary.summary_id, true)}
              icon={faCheck}
              cursor="pointer"
              size="lg"
              className="iconWithMarginRight"
            />
          )}
        </div>
      </div>

      <div
        className="summaryCard__description"
        dangerouslySetInnerHTML={{ __html: summary.description }}
      />
      <div className="summaryCard__metaData">
        <span>Napisane przez: {summary.author}</span>
        <strong>Przedmiot: {summary.subject_name}</strong>
      </div>
    </div>
  );
};
