import { API_URL } from "../constants";

export const getTrimesters = async () => {
  try {
    const response = await global.fetch(`${API_URL}/trimesters`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const activateTrimester = async (trimesterId) => {
  try {
    await global.fetch(`${API_URL}/trimesters/${trimesterId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
};
