import { API_URL } from "../constants";

export const getNews = async () => {
  const customHeaders: { trimesterId?: string } = {};
  if (window.sessionStorage.getItem("trimesterId")) {
    customHeaders["X-Active-Trimester"] =
      window.sessionStorage.getItem("trimesterId");
  }
  try {
    const response = await global.fetch(`${API_URL}/news`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...customHeaders,
      },
      credentials: "include",
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getNewsDetails = async (newsId) => {
  try {
    const response = await global.fetch(`${API_URL}/news/${newsId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const saveNews = async (news: any) => {
  try {
    const response = await global.fetch(`${API_URL}/news`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(news),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const updateNews = async (news: any) => {
  try {
    const response = await global.fetch(`${API_URL}/news`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(news),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const deleteNews = async (newsId) => {
  try {
    await global.fetch(`${API_URL}/news/${newsId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
};

export const addComment = async (newsId, comment) => {
  try {
    const response = await global.fetch(`${API_URL}/news/${newsId}/comment`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comment),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const removeComment = async (commentId) => {
  try {
    await global.fetch(`${API_URL}/news/comments/${commentId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
};

export const editComment = async (newsId, commentId, comment) => {
  try {
    await global.fetch(`${API_URL}/news/${newsId}/comment/${commentId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comment),
    });
  } catch (e) {
    console.log(e);
  }
};
