import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import { useContext, useEffect, useState } from "react";
import Container from "../../components/Container";
import { SingleNews } from "./SingleNews";
import { ModalContext } from "../../contexts/ModalContext";
import { NewsForm } from "./NewsForm";
import { getNews } from "../../api/newsClient";
import { UserContext } from "../../contexts/UserContextProvider";
import { ServerMessage } from "../../components/ServerMessage/ServerMessage";

const NewsList = () => {
  const { isManagement, isTeacher } = useContext(UserContext);

  const [news, setNews] = useState([]);
  const [serverMessage, setServerMessage] = useState(null);
  const { showModal } = useContext(ModalContext);
  const showGroupMessageModal = () => {
    showModal(NewsForm, {
      setServerMessage,
    });
  };

  const _fetchNews = async () => {
    try {
      const fetchedNews = await getNews();
      setNews(fetchedNews.news);
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message: "Nie udało się pobrać ogłoszeń.",
      });
    }
  };

  const updateNews = (updatedNews) => {
    setNews(
      news.map((n) =>
        n.news_id === updatedNews.news_id ? { ...n, ...updatedNews } : n,
      ),
    );
  };

  const removeNews = (newsId) => {
    setNews((n) => n.filter((news) => news.news_id !== newsId));
  };

  useEffect(() => {
    _fetchNews();
  }, []);

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1>Tablica Ogłoszeń</h1>
        {(isManagement || isTeacher) && (
          <Button type="primary" onClick={showGroupMessageModal}>
            Dodaj
          </Button>
        )}
      </Box>

      <ServerMessage messageObj={serverMessage} />
      <Box display="flex" flexDirection="column">
        {news.map((single) => (
          <SingleNews
            news={single}
            updateNews={updateNews}
            setServerMessage={setServerMessage}
            removeNews={removeNews}
          />
        ))}
      </Box>
    </Container>
  );
};

export default NewsList;
