import React from "react";

import { BrowserRouter, Switch } from "react-router-dom";
import Users from "../users/Users";
import Navigation from "./navigation/Navigation";
import SubjectCategoriesList from "../subjects/SubjectCategoriesList";
import Subjects from "../subjects/Subjects";
import SubjectUsersList from "../subjects/SubjectUsersList";
import UserGoals from "../users/UserGoals";
import ProtectedRoute from "../../components/ProtectedRoute";
import { UserRole } from "../../constants";
import SummaryList from "../summaries/SummaryList";
import Welcome from "./Welcome";
import UserGroups from "../userGroups/UserGroups";
import UserGroupList from "../userGroups/UserGroupList";
import SchoolYears from "../schoolYears/SchoolYears";
import Reports from "../reports/Reports";
import NewsList from "../news/NewsList";
import News from "../news/News";
import { ContextMenu } from "./ContextMenu/ContextMenu";

import styles from "./Home.module.css";
import {Profile} from "../profile/Profile";

const Home = () => (
  <BrowserRouter basename="/">
    <div className={styles.wrapper}>
      <Navigation />
      <main>
        <ContextMenu />
        <Switch>
          <ProtectedRoute exact path="/ogloszenia" component={NewsList} />
          <ProtectedRoute exact path="/ogloszenia/:newsId" component={News} />
          <ProtectedRoute
            exact
            path="/uzytkownicy"
            component={Users}
            roles={[UserRole.ROOT, UserRole.ADMIN, UserRole.MENTOR]}
          />
            <ProtectedRoute
                exact
                path="/profil/:userId"
                component={Profile}
                roles={[UserRole.ROOT, UserRole.ADMIN, UserRole.MENTOR, UserRole.TEACHER, UserRole.STUDENT]}
            />
          <ProtectedRoute
            exact
            path="/podsumowania"
            component={SummaryList}
            roles={[UserRole.ROOT, UserRole.ADMIN, UserRole.MENTOR]}
          />
          <ProtectedRoute
            exact
            path="/raporty"
            component={Reports}
            roles={[
              UserRole.ROOT,
              UserRole.ADMIN,
              UserRole.MENTOR,
              UserRole.TEACHER,
            ]}
          />
          <ProtectedRoute
            exact
            path="/rokszkolny"
            component={SchoolYears}
            roles={[UserRole.ROOT, UserRole.ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/przedmioty/bloki"
            component={SubjectCategoriesList}
            roles={[UserRole.ROOT, UserRole.ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/przedmioty/"
            component={Subjects}
            roles={[
              UserRole.ROOT,
              UserRole.ADMIN,
              UserRole.TEACHER,
              UserRole.MENTOR,
            ]}
          />
          <ProtectedRoute
            exact
            path="/przedmioty/:subjectId"
            component={SubjectUsersList}
            roles={[
              UserRole.ROOT,
              UserRole.ADMIN,
              UserRole.TEACHER,
              UserRole.MENTOR,
            ]}
          />
          <ProtectedRoute
            exact
            path="/uzytkownicy/grupy"
            component={UserGroups}
            roles={[
              UserRole.ROOT,
              UserRole.ADMIN,
              UserRole.MENTOR,
              UserRole.TEACHER,
            ]}
          />
          <ProtectedRoute
            exact
            path="/uzytkownicy/:userId/cele/:subjectId"
            component={UserGoals}
          />
          <ProtectedRoute
            exact
            path="/uzytkownicy/grupy/:groupId"
            component={UserGroupList}
            roles={[
              UserRole.ROOT,
              UserRole.ADMIN,
              UserRole.MENTOR,
              UserRole.TEACHER,
            ]}
          />
          <ProtectedRoute path="/" component={Welcome} />
        </Switch>
      </main>
    </div>
  </BrowserRouter>
);

export default Home;
