import Box from "@mui/material/Box";
import Container from "../../components/Container";
import Button from "../../components/Button/Button";
import React, { useContext, useEffect, useState } from "react";
import { deleteSubject, getSubjects } from "../../api/subjectClient";

import { ModalContext } from "../../contexts/ModalContext";
import SubjectForm from "./SubjectForm";
import { withRouter } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";
import { UserContext } from "../../contexts/UserContextProvider";
import { ServerMessage } from "../../components/ServerMessage/ServerMessage";
import {SubjectsColumnType} from "../../models/TableModel";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Chip from "@mui/material/Chip";
import ButtonMUI from "@mui/material/Button";

const TABLE_COLUMNS: readonly SubjectsColumnType[] = [
  { id: "name", label: "Nazwa przedmiotu" },
  { id: "code", label: "Kod przedmiotu" },
  { id: "block_name", label: "Blok" },
  { id: "trimester", label: "Trymestr" },
  {
    id: "actions",
    label: "",
    align: "right",
  },
];

const Subjects = ({ history }) => {
  const [subjects, setSubjects] = useState([]);
  const { showModal } = useContext(ModalContext);
  const { isManagement, isMentor } = useContext(UserContext);
  const [serverMessage, setServerMessage] = useState(null);

  const _fetchSubjects = async () => {
    try {
      const fetchedSubjects = await getSubjects();
      setSubjects(fetchedSubjects.subjects);
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message: "Nie udało się pobrać przedmiotów.",
      });
    }
  };

  const updateSubject = (newSubject, subjectId = null) => {
    if (subjectId) {
      setSubjects((s) =>
        s.map((subject) =>
          subject.subject_id === newSubject.subject_id
            ? { ...subject, ...newSubject }
            : subject,
        ),
      );
      return;
    }
    setSubjects([...subjects, newSubject]);
    return;
  };

  useEffect(() => {
    _fetchSubjects();
  }, []);

  const showDetailsModal = (subject = null) =>
    showModal(SubjectForm, {
      updateSubject,
      setServerMessage,
      ...(subject ? { subjectDetails: subject } : {}),
    });

  const redirectToManageUsers = (subjectId) => {
    history.push(`/przedmioty/${subjectId}`);
  };

  const handleSubjectDelete = async (subjectId) => {
    try {
      await deleteSubject(subjectId).then((_) => {
        setSubjects((s) =>
          s.filter((subject) => subject.subject_id !== subjectId),
        );
        setServerMessage({
          type: "SUCCESS",
          message: "Pomyślnie usunięto przedmiot.",
        });
      });
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message: "Nie udało się usunąć przedmiotu.",
      });
    }
  };

  const showDeleteModal = (subjectId) => {
    showModal(ConfirmationModal, {
      action: () => handleSubjectDelete(subjectId),
      text: "Czy na pewno chcesz usunąć przedmiot? Operacja jest nieodwracalna",
    });
  };

  if (!subjects) {
    return null;
  }

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1>Przedmioty</h1>
        {isManagement && (
          <Box>
            <Button type="primary" onClick={() => showDetailsModal()}>
              Dodaj
            </Button>
          </Box>
        )}
      </Box>
      <ServerMessage messageObj={serverMessage} />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {TABLE_COLUMNS.map((column) => (
                  <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        backgroundColor: "#004e8d",
                        color: "#fff",
                      }}
                  >
                    {column.label}
                  </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {subjects.map((subject) => (
                <TableRow hover tabIndex={-1} key={subject.subject_id}>
                  <TableCell>{subject.name}</TableCell>
                  <TableCell>{subject.code}</TableCell>
                  <TableCell>
                    <Chip label={subject.category_name}  sx={{
                      backgroundColor: subject.category_color,
                    }} />
                  </TableCell>
                  <TableCell>{subject.trimester_code}</TableCell>
                  <TableCell align="right">
                    <ButtonMUI
                        variant="outlined"
                        size="small"
                        sx={{marginRight: '8px'}}
                        onClick={() => redirectToManageUsers(subject.subject_id)}
                    >
                      Zarządzaj
                    </ButtonMUI>
                    {isManagement && (<ButtonMUI
                        variant="outlined"
                        size="small"
                        sx={{marginRight: '8px'}}
                        onClick={() => showDetailsModal(subject)}
                    >
                      Edytuj
                    </ButtonMUI>)}
                    {(isManagement && !isMentor) && (<ButtonMUI
                        variant="outlined"
                        size="small"
                        color="error"
                        sx={{marginRight: '8px'}}
                        onClick={() => showDeleteModal(subject.subject_id)}
                    >
                      Usuń
                    </ButtonMUI>)}
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      {/*  {subjects.map((subject) => (*/}
      {/*    <TableRow>*/}
      {/*      <TableTd>{subject.name}</TableTd>*/}
      {/*      <TableTd>{subject.code}</TableTd>*/}
      {/*      <TableTd>*/}
      {/*        <TypeWithBgColor color={subject.category_color}>*/}
      {/*          {subject.category_name}*/}
      {/*        </TypeWithBgColor>*/}
      {/*      </TableTd>*/}
      {/*      <TableTd>{subject.trimester_code}</TableTd>*/}
      {/*      <TableTd>*/}
      {/*        <FontAwesomeIcon*/}
      {/*          onClick={() => redirectToManageUsers(subject.subject_id)}*/}
      {/*          icon={faUsers}*/}
      {/*          cursor="pointer"*/}
      {/*          size="lg"*/}
      {/*          className="iconWithMarginRight"*/}
      {/*        />*/}
      {/*        {isManagement && (*/}
      {/*          <FontAwesomeIcon*/}
      {/*            onClick={() => showDetailsModal(subject)}*/}
      {/*            icon={faEdit}*/}
      {/*            cursor="pointer"*/}
      {/*            size="lg"*/}
      {/*            className="iconWithMarginRight"*/}
      {/*          />*/}
      {/*        )}*/}
      {/*        {isManagement && !isMentor && (*/}
      {/*          <FontAwesomeIcon*/}
      {/*            onClick={() => showDeleteModal(subject.subject_id)}*/}
      {/*            icon={faTrashAlt}*/}
      {/*            cursor="pointer"*/}
      {/*            size="lg"*/}
      {/*          />*/}
      {/*        )}*/}
      {/*      </TableTd>*/}
      {/*    </TableRow>*/}
      {/*  ))}*/}
      {/*</Table>*/}
    </Container>
  );
};

export default withRouter(Subjects);
