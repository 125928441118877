import React, { useContext, useEffect, useState } from "react";
import Container from "../../components/Container";

import "./UserGoals.scss";
import Button from "../../components/Button/Button";
import Goal from "../goals/Goal";
import {
  deleteGoal,
  getGoals,
  saveGoals,
  updateGoal,
} from "../../api/goalsClient";
import { withRouter } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";
import { ModalContext } from "../../contexts/ModalContext";
import Summary from "../summaries/Summary";
import { UserContext } from "../../contexts/UserContextProvider";
import { getSummary } from "../../api/summariesClient";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SubjectNote from "../subjects/SubjectNote";
import { getNote } from "../../api/noteClient";
import { UsersNavigation } from "./UsersNavigation";
import { GroupContext } from "../../contexts/GroupContext";

const UserGoals = ({ history }) => {
  const subjectId = history.location.pathname.split("/")[4];
  const userId = history.location.pathname.split("/")[2];
  const [iter, setIter] = useState(0);
  const [subjectName, setSubjectName] = useState({ name: null, code: null });
  const [studentName, setStudentName] = useState({
    first_name: "",
    last_name: "",
  });
  const [initialGoals, setInitialGoals] = useState([]);
  const [goals, setGoals] = useState([]);
  const [isHiddenVisible, setIsHiddenVisible] = useState(false);
  const [summary, setSummary] = useState({
    summary_id: "",
    description: "",
    author: "",
    published: false,
    final_grade: "",
    proposed_grade: ""
  });
  const [note, setNote] = useState({ note_id: "", description: "" });

  const [temporaryGoals, setTemporaryGoals] = useState([]);
  const { showModal } = useContext(ModalContext);
  const { isStudent } = useContext(UserContext);
  const { students, setCurrentEditingIndex } = useContext(GroupContext);

  const [noteVisible, setNoteVisible] = useState(!isStudent);

  const _fetchGoals = async () => {
    const response = await getGoals({ subjectId, userId });
    setSubjectName({
      name: response.subject_name,
      code: response.subject_code,
    });
    setStudentName({
      first_name: response.student.first_name,
      last_name: response.student.last_name,
    });
    setGoals(response.goals.filter((goal) => !goal.hidden));
    setInitialGoals(response.goals);
  };

  const _fetchSummary = async () => {
    const response = await getSummary({ subjectId, userId });
    setSummary(response);
  };

  const _fetchNote = async () => {
    const response = await getNote({ subjectId, userId });
    setNote(response);
  };

  const fetchData = () => {
    _fetchGoals();
    _fetchSummary();
    _fetchNote();
  };

  useEffect(() => {
    const currentIndex = students.findIndex((user) => user.user_id === userId);
    setCurrentEditingIndex(currentIndex);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [userId]);

  const isSaveButtonDisabled =
    !temporaryGoals.length ||
    temporaryGoals.some((goal) => goal.description === "");

  const addTemporaryGoal = () => {
    setTemporaryGoals([
      ...temporaryGoals,
      { goal_id: `temp${iter + 1}`, description: "", result: "" },
    ]);
    setIter(iter + 1);
  };

  const handleTemporaryGoalDelete = (id) =>
    setTemporaryGoals(temporaryGoals.filter((goal) => goal.goal_id !== id));

  const handleTemporaryGoalUpdate = (updatedGoal) =>
    setTemporaryGoals(
      temporaryGoals.map((goal) =>
        goal.goal_id === updatedGoal.goal_id
          ? { ...goal, ...updatedGoal }
          : goal,
      ),
    );

  const handleGoalUpdate = (updatedGoal) =>
    setGoals(
      goals.map((goal) =>
        goal.goal_id === updatedGoal.goal_id
          ? { ...goal, ...updatedGoal }
          : goal,
      ),
    );

  const onSave = async () => {
    const dataToSend: {
      goals: { description: string; result: string; goal_id?: string }[];
    } = {
      goals: temporaryGoals.map((goal) => ({
        description: goal.description,
        result: goal.result,
      })),
    };

    const response = await saveGoals({ goals: dataToSend, subjectId, userId });

    dataToSend.goals.forEach((data, index) => {
      dataToSend.goals[index] = response.goalsIds[index];
    });

    setGoals([...goals, ...dataToSend.goals]);
    setTemporaryGoals([]);
  };

  const onApiUpdate = async (newGoal) => {
    const response = await updateGoal(newGoal);
    setGoals((g) =>
      g.map((goal) =>
        goal.goal_id === response.goal_id ? { ...goal, ...response } : goal,
      ),
    );
  };

  const handleGoalDelete = async (goalId) => {
    await deleteGoal(goalId).then((_) =>
      setGoals((g) => g.filter((goal) => goal.goal_id !== goalId)),
    );
  };

  const showDeleteModal = (goalId) => {
    showModal(ConfirmationModal, {
      action: () => handleGoalDelete(goalId),
      text: "Czy na pewno chcesz usunąć cel? Operacja jest nieodwracalna",
    });
  };

  const changeGoalVisibility = () => {
    setIsHiddenVisible(!isHiddenVisible);

    isHiddenVisible
      ? setGoals(goals.filter((goal) => !goal.hidden))
      : setGoals(initialGoals);
  };

  return (
    <Container>
      {students.length > 0 && <UsersNavigation subjectId={subjectId} />}
      <h1 className="goal__studentName">
        {studentName.first_name} {studentName.last_name}
      </h1>
      <div className="goal__subControls">
        <h2>
          {subjectName.name} ({subjectName.code})
        </h2>
        <Button type="secondary" onClick={changeGoalVisibility}>
          {isHiddenVisible ? "Schowaj ukryte" : "Pokaż ukryte"}
        </Button>
      </div>
      {goals.map((goal) => (
        <Goal
          key={goal.goal_id}
          isBlocked={summary.published}
          goal={goal}
          updateGoal={handleGoalUpdate}
          onApiUpdate={onApiUpdate}
          onDelete={showDeleteModal}
        />
      ))}
      {temporaryGoals.map((goal) => (
        <Goal
          key={goal.goal_id}
          goal={goal}
          isBlocked={summary.published}
          editMode
          updateGoal={handleTemporaryGoalUpdate}
          onDelete={handleTemporaryGoalDelete}
        />
      ))}
      <div className="goals__controls">
        {!isStudent && !summary.published && (
          <Button
            type="primary"
            disabled={isSaveButtonDisabled}
            onClick={onSave}
            withRightMargin
            mobileFullWidth
          >
            Zapisz {temporaryGoals.length > 1 ? "cele" : "cel"}
          </Button>
        )}
        {!isStudent && !summary.published && (
          <Button
            type="primary"
            mobileFullWidth
            withRightMargin
            onClick={addTemporaryGoal}
          >
            Dodaj {temporaryGoals.length ? "kolejny" : ""} cel
          </Button>
        )}
        {isStudent && (
          <FontAwesomeIcon
            cursor="pointer"
            onClick={() => setNoteVisible(!noteVisible)}
            icon={faEye}
            size="2x"
          />
        )}
      </div>

      <SubjectNote
        note={note}
        isVisible={noteVisible}
        isBlocked={summary.published}
        setNote={setNote}
      />

      <Summary
        summary={summary}
        isDisabled={
          !!goals.filter((goal) => goal.result === "").length || !goals.length
        }
        setSummary={setSummary}
      />

      {students.length > 0 && <UsersNavigation subjectId={subjectId} />}
    </Container>
  );
};

export default withRouter(UserGoals);
