import { useState } from "react";
import Textarea from "../../../components/Textarea/Textarea";
import Button from "../../../components/Button/Button";

export const CommentInput = ({ onCommentSave }) => {
  const [content, setContent] = useState("");

  const onSend = () => {
    onCommentSave(content);
    setContent("");
  };

  return (
    <div>
      <Textarea id="news-comment-input" value={content} onChange={setContent} />
      <Button type="primary" onClick={onSend}>
        Wyślij
      </Button>
    </div>
  );
};
