import "./ErrorMessage.scss";

const ErrorMessage = ({ message }) => {
  if (!message) {
    return null;
  }
  return <span className="errorMessage">{message}</span>;
};

export default ErrorMessage;
