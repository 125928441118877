import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserContextProvider";
import Container from "../../components/Container";
import TeacherMessage from "../../TeacherMessage";
import StudentMessage from "../../StudentMessage";

const Welcome = () => {
  const { isStudent } = useContext(UserContext);

  return (
    <Container>
      <h1>Indywidualna Droga Edukacyjnego Rozwoju (IDER)</h1>
      {isStudent ? <StudentMessage /> : <TeacherMessage />}
    </Container>
  );
};

export default Welcome;
