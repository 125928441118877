import { API_URL } from "../constants";

export const getUserDetailsReport = async (user, filters = null) => {
  let queryParams = `?`;
  let endpointUrl = `${API_URL}/reports/${user.id}`;

  if (filters) {
    queryParams += `year=${filters.schoolYear}`;

    if (filters.trimesters.length) {
      queryParams += `&trimesters=${filters.trimesters.join(",")}`;
    }

    if (filters.details.length) {
      queryParams += `&details=${filters.details.join(",")}`;
    }

    if (filters.subjects.length) {
      queryParams += `&subjects=${filters.subjects.join(",")}`;
    }

    endpointUrl += queryParams;
  }

  try {
    const response = await global.fetch(endpointUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};
