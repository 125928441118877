import React from "react";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { ModalContext } from "../contexts/ModalContext";
import Box from "@mui/material/Box";
import Button from "./Button/Button";

const ConfirmationModal = ({ action, text }) => {
  const { hideModal } = useContext(ModalContext);

  const handleConfirm = () => {
    action();
    hideModal();
  };
  return (
    <React.Fragment>
      <Typography>
        <strong>{text}</strong>
      </Typography>

      <Box display="flex" marginTop="32px">
        <Button type="primary" onClick={handleConfirm}>
          Potwierdź
        </Button>
        <Box marginLeft="16px">
          <Button type="secondary" onClick={hideModal}>
            Anuluj
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ConfirmationModal;
