import { API_URL } from "../constants";

export const getGroups = async () => {
  try {
    const response = await global.fetch(`${API_URL}/groups`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getGroupsForSubject = async (subjectId) => {
  try {
    const response = await global.fetch(
      `${API_URL}/groups/subjects/${subjectId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const assignUserToGroup = async (groupId, userId) => {
  try {
    const response = await global.fetch(
      `${API_URL}/groups/${groupId}/assign/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const unassignUserFromGroup = async (groupId, userId) => {
  try {
    await global.fetch(`${API_URL}/groups/${groupId}/unassign/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
};

export const getGroupDetails = async (groupId) => {
  try {
    const response = await global.fetch(`${API_URL}/groups/${groupId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const saveGroup = async (group) => {
  try {
    const response = await global.fetch(`${API_URL}/groups`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(group),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const updateGroup = async (groupId, group) => {
  try {
    const response = await global.fetch(`${API_URL}/groups/${groupId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(group),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const deleteGroup = async (groupId) => {
  try {
    await global.fetch(`${API_URL}/groups/${groupId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendGroupMessage = async (groupId, payload) => {
  try {
    await global.fetch(`${API_URL}/groups/${groupId}/send-message`, {
      method: "POST",
      credentials: "include",
      body: payload,
    });
  } catch (e) {
    console.log(e);
  }
};
