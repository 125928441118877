import { API_URL } from "../constants";

export const getNote = async ({ subjectId, userId }) => {
  try {
    const response = await global.fetch(
      `${API_URL}/note/${subjectId}/user/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const saveNote = async ({ subjectId, userId, note }) => {
  try {
    const response = await global.fetch(`${API_URL}/note`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...note,
        subject_id: subjectId,
        user_id: userId,
      }),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const updateNote = async (noteId, descriptionObj) => {
  try {
    const response = await global.fetch(`${API_URL}/note/${noteId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(descriptionObj),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};
