import ReactQuill from "react-quill";
import { ModalContext } from "../../contexts/ModalContext";
import React, { useEffect, useState, useContext } from "react";
import Button from "../../components/Button/Button";
import { getSubjects } from "../../api/subjectClient";
import Select from "../../components/Select/Select";
import { getGroupsForSubject } from "../../api/userGroupsClient";
import FormGroup from "@mui/material/FormGroup";
import { Checkbox, FormControlLabel, Paper } from "@mui/material";
import { saveNews, updateNews as updateNewsAPI } from "../../api/newsClient";
import { FileInput } from "../../components/FileInput/FileInput";
import { API_URL } from "../../constants";

import "react-quill/dist/quill.snow.css";

const DEADLINE_VALUES = [
  {label: '3 dni', value: 3},
  {label: '7 dni', value: 7},
  {label: '10 dni', value: 10},
  {label: '14 dni', value: 14},
  {label: '21 dni', value: 21},
]

export const NewsForm = ({ setServerMessage, updateNews, news = null }) => {
  const newsChosenGroupsList = news ? news?.groups?.split(", ") : [];

  const { hideModal } = useContext(ModalContext);
  const [files, setFiles] = useState(null);

  const [message, setMessage] = useState(news ? news.message : "");
  const [deadline, setDeadline] = useState(10);
  const [subjects, setSubjects] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(
    news ? news.subject_id : null,
  );
  const [chosenGroups, setChosenGroups] = useState(
    news ? newsChosenGroupsList : [],
  );
  const _fetchSubjects = async () => {
    const fetchedSubjects = await getSubjects();
    setSubjects(fetchedSubjects.subjects);
  };

  const _fetchGroupsFromSubject = async () => {
    const fetchedGroups = await getGroupsForSubject(selectedSubject);
    setGroups(fetchedGroups.groups);
  };

  const parseSubjectsOptions = (subjects): { value: string; label: string }[] =>
    subjects.map((subject) => ({
      value: subject.subject_id,
      label: subject.name,
    }));

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setChosenGroups(
      chosenGroups.includes(value)
        ? chosenGroups.filter((item) => item !== value)
        : [...chosenGroups, value],
    );
  };

  const sendNews = async () => {
    const formData = new FormData();
    if (files) {
      for (const single_file of files) {
        formData.append("files_to_upload", single_file);
      }
    }

    const payload = {
      subject_id: selectedSubject,
      subject_name: subjects.filter(
        (subject) => subject.subject_id === selectedSubject,
      )[0].name,
      groups: chosenGroups,
      message,
    };

    if (news) {
      try {
        const updatedNews = await updateNewsAPI({
          ...payload,
          news_id: news.news_id,
        });
        updateNews(updatedNews);

        setServerMessage({
          type: "SUCCESS",
          message: "Zaktualizowano ogłoszenie.",
        });
      } catch (err) {
        setServerMessage({
          type: "ERROR",
          message:
            "Nie udało się edytować ogłoszenia. Spróbuj ponownie później.",
        });
      }
    } else {
      try {
        const res = await saveNews({...payload, deadline});
        if (files) {
          formData.append("news_id", res.news_id);

          await fetch(`${API_URL}/files/upload?uploadType=NEWS`, {
            method: "POST",
            credentials: "include",
            body: formData,
          });
        }

        setServerMessage({
          type: "SUCCESS",
          message: "Dodano nowe ogłoszenie. Odśwież stronę.",
        });
      } catch (err) {
        setServerMessage({
          type: "ERROR",
          message:
            "Nie udało się dodać nowego ogłoszenia. Spróbuj ponownie później.",
        });
      }
    }

    hideModal();
  };

  useEffect(() => {
    _fetchSubjects();
  }, []);

  useEffect(() => {
    if (selectedSubject) {
      _fetchGroupsFromSubject();
    }
  }, [selectedSubject]);

  return (
    <div>
      <span>Wybierz przedmiot</span>

      <Select
        isButtonDisabled={subjects.length === 0}
        options={parseSubjectsOptions(subjects)}
        method={(value) => setSelectedSubject(value)}
        withButton={false}
        value={news ? { label: news.subject_name, value: selectedSubject } : ""}
        noOptionsMessage="brak przedmiotów"
      />

      {groups && (
        <Paper style={{ maxHeight: 130, boxShadow: "none", overflow: "auto" }}>
          <FormGroup>
            {groups.map((group) => (
              <FormControlLabel
                onChange={handleCheckboxChange}
                value={group.group_id}
                name={group.code}
                control={
                  <Checkbox
                    defaultChecked={
                      news
                        ? newsChosenGroupsList.includes(group.group_id)
                        : false
                    }
                  />
                }
                label={group.name}
              />
            ))}
          </FormGroup>
        </Paper>
      )}

      {!news && (<span>Czas widoczności:</span>)}


      {!news && (<Select
          options={DEADLINE_VALUES}
          method={(value) => setDeadline(value)}
          withButton={false}
          value={DEADLINE_VALUES.filter(item => item.value === 10)}
      />)}

      <ReactQuill
        theme="snow"
        id="newsTextArea"
        value={message}
        onChange={setMessage}
        readOnly={!chosenGroups.length}
      />
      {!news && (
        <FileInput
          accept="image/*,.doc,.docx,.pdf"
          multiple
          onUpload={(files) => setFiles(files)}
        />
      )}
      <Button
        type="primary"
        onClick={sendNews}
        disabled={!message.length || !chosenGroups.length}
      >
        Wyślij
      </Button>
    </div>
  );
};
