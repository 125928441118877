import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Button from "../../components/Button/Button";
import { getUserDetailsReport } from "../../api/reportsClient";
import { generateReportPrintTemplate } from "./generateReportPrintTemplate";
import Select from "../../components/Select/Select";
import { getUsers } from "../../api/usersClient";
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import { getSchoolYears } from "../../api/schoolYearsClient";

import { getSubjectsFromTrimester } from "../../api/subjectClient";


const Reports = () => {
  const [users, setUsers] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filteredSubjects, setFilteredSubjects] = useState(null);
  const [isFilterBySubjects, setIsFilterBySubjects] = useState(false);
  const [filterType, setFilterType] = useState("FULL_REPORT");
  const [selectedYear, setSelectedYear] = useState(null);
  const [filteredData, setFilteredData] = useState({
    trimesters: [],
    details: [],
    subjects: [],
  });

  const _fetchUsers = async () => {
    const fetchedUsers = await getUsers();
    setUsers(fetchedUsers.users);
  };

  const _fetchSchoolYears = async () => {
    const response = await getSchoolYears();
    setYears(response.years);
  };

  const _fetchSubjectsFromTrimesters = async (trimesters) => {
    const response = await getSubjectsFromTrimester(trimesters);
    setFilteredSubjects(response)
  }

  useEffect(() => {
    _fetchUsers();
    _fetchSchoolYears();
  }, []);

  const parseUsersOptions = (users): { value: object; label: string }[] =>
    users.map((user) => ({
      value: { id: user.user_id, name: `${user.first_name} ${user.last_name}` },
      label: `${user.first_name} ${user.last_name} (${user.email})`,
    }));

  const parseYearsOptions = (years): { value: string; label: string }[] =>
    years.map((year) => ({
      value: {code: `${year.year_started_at}/${year.year_ended_at}`, year_id: year.year_id},
      label: `${year.year_started_at}/${year.year_ended_at}`,
    }));

  const generatePrint = async () => {
    let filters = null;

    if (selectedYear) {
      filters = {
        schoolYear: selectedYear.year_id,
        ...filteredData,
      };
    }
    const dataToPrint = await getUserDetailsReport(selectedUser, filters);
    const template = generateReportPrintTemplate(
      dataToPrint,
      selectedUser.name,
    );
    const newPrint = window.open();
    const newDocument = newPrint.document;
    const handler = newDocument.createElement("div");
    handler.innerHTML = template;
    newDocument.body.appendChild(handler);
    newDocument.close();

    newPrint.focus();
    newPrint.print();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedYear(null);
    setFilteredData({ trimesters: [], details: [], subjects: [] });
    setFilterType((event.target as HTMLInputElement).value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const baseArray = filteredData[event.target.name];
    const { value } = event.target;
   

    if(event.target.name === 'trimesters'){
      setFilteredData({
        ...filteredData,
        subjects: [],
        [event.target.name]: baseArray.includes(value)
          ? baseArray.filter((item) => item !== value)
          : [...baseArray, value]
      })
      setIsFilterBySubjects(false);
      setFilteredSubjects([]);
    }else{
      setFilteredData({
        ...filteredData,
        [event.target.name]: baseArray.includes(value)
          ? baseArray.filter((item) => item !== value)
          : [...baseArray, value],
      });
    }
  };
  
  const handleSelectedYearChange = (value) => {
    setSelectedYear(value)
  }

  const onSubjectFilter = (event) => {
    const trimestersForFetch = (filteredData.trimesters as unknown as string[]).map(trimester => `${selectedYear.code}${trimester}`)

    setIsFilterBySubjects((prev) => {
      if(!prev === false){
        setFilteredSubjects(null)
        setFilteredData({
          ...filteredData,
          subjects: []
        })
      }else{
        _fetchSubjectsFromTrimesters(trimestersForFetch);
      }
      return !prev
    })
  }

  const isPrintButtonDisabled =
    !selectedUser ||
    (!selectedYear?.year_id && filterType === "FILTERED_REPORT") ||
    (selectedYear?.year_id && !filteredData.trimesters.length) ||
    (selectedYear?.year_id &&
      filteredData.trimesters.length &&
      !filteredData.details.length);

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1>Wygeneruj raport</h1>
      </Box>

      <Select
        isButtonDisabled={users.length === 0}
        options={parseUsersOptions(users)}
        method={(value) => setSelectedUser(value)}
        withButton={false}
        noOptionsMessage="brak uczniów"
      />

      <Box marginBottom="16px" marginTop="16px">
        <RadioGroup
          aria-labelledby="report-radio-buttons"
          defaultValue="FULL_REPORT"
          name="radio-buttons-group"
          onChange={handleChange}
        >
          <FormControlLabel
            disabled={!selectedUser}
            value="FULL_REPORT"
            control={<Radio />}
            label="Wszystkie dane"
          />
          <FormControlLabel
            disabled={!selectedUser}
            value="FILTERED_REPORT"
            control={<Radio />}
            label="Filtruj dane"
          />
        </RadioGroup>
      </Box>

      {filterType === "FILTERED_REPORT" && (
        <Box display="flex" flexDirection="column">
          <Select
            isButtonDisabled={years.length === 0}
            options={parseYearsOptions(years)}
            method={(value) => handleSelectedYearChange(value)}
            withButton={false}
            noOptionsMessage="brak danych"
          />

          {selectedYear?.year_id && (
            <>
              <Box marginBottom="16px" marginTop="16px">
                <strong>Wybierz trymestry</strong>
                <FormGroup>
                  <FormControlLabel
                    onChange={handleCheckboxChange}
                    value="_1"
                    name="trimesters"
                    control={<Checkbox />}
                    label="Trymestr 1"
                  />
                  <FormControlLabel
                    onChange={handleCheckboxChange}
                    value="_2"
                    name="trimesters"
                    control={<Checkbox />}
                    label="Trymestr 2"
                  />
                  <FormControlLabel
                    onChange={handleCheckboxChange}
                    value="_3"
                    name="trimesters"
                    control={<Checkbox />}
                    label="Trymestr 3"
                  />
                </FormGroup>
              </Box>
              {!!filteredData.trimesters.length && (<Box marginBottom="16px">
                <FormGroup>
                  <FormControlLabel control={<Switch checked={isFilterBySubjects} onChange={onSubjectFilter}  />} label="Wskaż przedmioty" />
                </FormGroup>
              </Box>)}
              {filteredSubjects && (<Grid marginBottom="16px" container spacing={2}>
                {Object.keys(filteredSubjects).map(trimester => (
                  <Grid item xs={4}>
                    <strong>{trimester}</strong>

                    {filteredSubjects[trimester].map(subject => (<FormGroup>
                      <FormControlLabel
                        onChange={handleCheckboxChange}
                        value={subject.subject_id}
                        name="subjects"
                        control={<Checkbox />}
                        label={subject.name}
                      />
                    </FormGroup>))}
                  </Grid>
                ))}
              </Grid>)}
              <Box marginBottom="16px">
                <strong>Wybierz zawartość</strong>
                <FormGroup>
                  <FormControlLabel
                    disabled={!filteredData.trimesters.length}
                    onChange={handleCheckboxChange}
                    value="goals"
                    name="details"
                    control={<Checkbox />}
                    label="Cele"
                  />
                  <FormControlLabel
                    disabled={!filteredData.trimesters.length}
                    onChange={handleCheckboxChange}
                    value="notes"
                    name="details"
                    control={<Checkbox />}
                    label="Notatki"
                  />
                  <FormControlLabel
                    disabled={!filteredData.trimesters.length}
                    onChange={handleCheckboxChange}
                    value="summary"
                    name="details"
                    control={<Checkbox />}
                    label="Podsumowanie"
                  />
                </FormGroup>
              </Box>
            </>
          )}
        </Box>
      )}

      <Box display="flex" marginTop="15px" alignItems="center">
        <Button
          onClick={generatePrint}
          disabled={isPrintButtonDisabled}
          type="primary"
        >
          Wydrukuj
        </Button>
      </Box>
    </Container>
  );
};

export default Reports;
