import { ChangeEvent, ReactElement, useState } from "react";
import "./Field.scss";

interface Props {
  id: string;
  label?: string;
  type?: "text" | "password" | "file";
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const Field = ({
  id,
  label,
  type = "text",
  value = "",
  disabled = false,
  onChange,
}: Props): ReactElement => (
  <div className="field">
    {label && (
      <label className="field__label" htmlFor={id}>
        {label}
      </label>
    )}
    <input
      className="field__input"
      id={id}
      type={type}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      disabled={disabled}
    />
  </div>
);

export default Field;
