import classNames from "classnames";

import "./Button.scss";

const Button = ({
  type,
  mobileFullWidth = false,
  children,
  onClick,
  noPadding = false,
  disabled = false,
  withRightMargin = false,
}) => (
  <button
    className={classNames({
      button: true,
      "button--primary": type === "primary",
      "button--secondary": type === "secondary",
      "button--danger": type === "danger",
      "button--disabled": disabled,
      "button--noPadding button--noBorder": noPadding,
      "button--marginRight15": withRightMargin,
      "button--mobileFullWidth": mobileFullWidth,
    })}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;
