import React, { Component, createContext } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ModalContext = createContext({
  showModal: (component: any, props: any) => {},
  hideModal: () => {},
});

export class ModalProvider extends Component<any> {
  state = {
    isVisible: false,
    component: null,
    props: null,
  };

  showModal = (component: any, props: any) => {
    this.setState({
      isVisible: true,
      component,
      props,
    });
  };

  hideModal = () => {
    this.setState({
      isVisible: false,
      props: null,
    });
  };

  contextValue = {
    showModal: this.showModal,
    hideModal: this.hideModal,
  };

  render() {
    const { component: Component, props, isVisible } = this.state;

    return (
      <ModalContext.Provider value={this.contextValue}>
        {!!Component && (
          <Modal
            open={isVisible}
            onClose={(event, reason) => {
              console.log(reason);
            }}
          >
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                position="relative"
                bgcolor="#fff"
                width="400px"
                display="flex"
                flexDirection="column"
                padding="16px"
              >
                <Box position="absolute" top="16px" right="16px">
                  <FontAwesomeIcon
                    onClick={this.hideModal}
                    icon={faXmark}
                    cursor="pointer"
                    size="lg"
                  />
                </Box>
                <Box marginTop="16px">
                  <Component {...props} onClose={this.hideModal} />
                </Box>
              </Box>
            </Box>
          </Modal>
        )}
        {this.props.children}
      </ModalContext.Provider>
    );
  }
}
