import { UserRole } from "../constants";

const getRoleNicename = (role) => {
  switch (role) {
    case UserRole.ROOT:
      return "Super Administrator";
    case UserRole.ADMIN:
      return "Administrator";
    case UserRole.MENTOR:
      return "Mentor";
    case UserRole.TEACHER:
      return "Nauczyciel";
    case UserRole.STUDENT:
      return "Uczeń";
    case UserRole.ALUMNI:
      return "Absolwent";
  }
};

export default getRoleNicename;
