const dev = {
  api: {
    URL: "http://localhost:5000",
  },
};

const test = {
  api: {
    URL: "https://test.ider-ade.pl/api",
  },
};

const prod = {
  api: {
    URL: "https://ider-ade.pl/api",
  },
};

const config = () => {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return dev;
    case "staging":
      return test;
    case "production":
      return prod;
    default:
      throw new Error("invalid app environment");
  }
};

export const APP_CONFIG = config();
