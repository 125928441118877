import ErrorMessage from "../ErrorMessage/ErrorMessage";
import SuccessMessage from "../SuccessMessage/SuccessMessage";

export const ServerMessage = ({ messageObj }) => {
  if (!messageObj) {
    return null;
  }

  return messageObj.type === "ERROR" ? (
    <ErrorMessage message={messageObj.message} />
  ) : (
    <SuccessMessage message={messageObj.message} />
  );
};
