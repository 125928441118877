import Textarea from "../../components/Textarea/Textarea";
import Button from "../../components/Button/Button";
import { useContext, useState } from "react";
import { updateSummary as updateSummaryAPI } from "../../api/summariesClient";
import { ModalContext } from "../../contexts/ModalContext";

const SummaryEditModal = ({ summaryId, description, updateSummary }) => {
  const [summary, setSummary] = useState(description);
  const { hideModal } = useContext(ModalContext);

  const saveSummary = async () => {
    try {
      await updateSummaryAPI(summaryId, { description: summary });
      updateSummary(summaryId, summary);
      hideModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Textarea
        id="summaryTextArea"
        label="Treść podsumowania"
        value={summary}
        onChange={setSummary}
      />

      <Button type="primary" onClick={saveSummary} disabled={!summary.length}>
        Wyślij
      </Button>
    </div>
  );
};

export default SummaryEditModal;
