import { API_URL } from "../constants";

export const downloadFile = (fileName: string) => {
  fetch(`${API_URL}/files/${fileName}`, { credentials: "include" })
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch((err) => console.error(err));
};
