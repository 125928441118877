export const generateReportPrintTemplate = (details, userName) => {
  console.log(userName);
  return details.length
    ? details.map((item, index) => {
        return `
            <style>
          
            p {
                margin: 0;
            }
            .printItemBox {
              border: 2px solid #ccc;
              padding: 15px 10px;
              margin-bottom: 15px;
            }
            .printSubjectHandler{
                margin-bottom: 30px;
            }
            .printSubjectHandler:last-of-type {
                margin-bottom: 0;
            }
            .printSubjectName {
              color: #004e8d;
              text-transform: uppercase;
              font-size: 24px;
              font-weight: 700;
              margin-bottom: 20px;
              margin-top: 20px;
            }
            </style>
            <div>
                <h1 style="color: #bc0000">${userName}</h1>
                <hr/>
                <h1>${item.year_started_at} / ${item.year_ended_at}</h1>
                ${item.trimesters
                  .map(
                    (trimester) =>
                      `
                        <h2>Trymestr: ${trimester.code}</h2>
                        ${trimester.subjects
                          .map(
                            (subject) =>
                              `
                            <div class="printSubjectHandler">
                            <span class="printSubjectName">${
                              subject.name
                            }</span>
                            ${
                              subject.goals.length
                                ? `<h3>Cele</h3>
                            ${subject.goals
                              .map(
                                (goal) =>
                                  `<div class="printItemBox"><p><strong>${goal.description}</strong></p><p>${goal.result}</p></div>`,
                              )
                              .join("")}`
                                : ""
                            }
                            ${
                              subject.notes.length
                                ? `<h3>Notatki</h3>
                            ${subject.notes
                              .map(
                                (note) =>
                                  `<div class="printItemBox">${note.description}</div>`,
                              )
                              .join("")}`
                                : ""
                            }
                            ${
                              subject.summaries.length
                                ? `<h3>Podsumowanie</h3>
                            ${subject.summaries
                              .map(
                                (summary) =>
                                  `<div class="printItemBox">${summary.description}</div>`,
                              )
                              .join("")}`
                                : ""
                            }
                            </div>
                            `,
                          )
                          .join("")}
                    `,
                  )
                  .join("")}
            </div>        `;
      })
    : "brak danych dla zadanych parametrów";
};
