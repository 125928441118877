import React, { useEffect, useState } from "react";

import "../subjects/SubjectStudentsListControls.scss";
import { assignUserToGroup } from "../../api/userGroupsClient";
import Select from "../../components/Select/Select";

const AddUserToGroupControls = ({ groupId, usersNotAssigned, onSave }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers(usersNotAssigned);
  }, [usersNotAssigned]);

  const assignUser = (selectedUserId) => {
    assignUserToGroup(groupId, selectedUserId).then((_) => {
      const newUsers = users.filter((u) => u.user_id !== selectedUserId);
      onSave(users.find((u) => u.user_id === selectedUserId));
      setUsers(newUsers);
    });
  };

  const parseOptions = (users): { value: string; label: string }[] =>
    users.map((user) => ({
      value: user.user_id,
      label: `${user.first_name} ${user.last_name} (${user.email})`,
    }));

  return (
    <Select
      isButtonDisabled={users.length === 0}
      options={parseOptions(users)}
      method={assignUser}
      noOptionsMessage="brak uczniów"
    />
  );
};

export default AddUserToGroupControls;
