import { default as ReactSelect } from "react-select";
import Button from "../Button/Button";
import React, { useState } from "react";

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: 300,
    marginRight: 15,
  }),
};

const Select = ({
  options,
  withButton = true,
  isButtonDisabled = false,
  method,
  toRight = false,
  noOptionsMessage = "",
  value = null,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState(value ? value : null);
  const handleButtonClick = () => {
    method(selectedOption.value);
    setSelectedOption(null);
  };

  const onSelectChange = (option) => {
    if (withButton) {
      setSelectedOption(option);
    } else {
      setSelectedOption(option);
      method(option.value);
    }
  };
  return (
    <div className={`controls ${toRight ? "controls--toRight" : ""}`}>
      <div className="controls__handler">
        <ReactSelect
          placeholder="Wybierz..."
          noOptionsMessage={() => noOptionsMessage}
          value={selectedOption}
          options={options}
          onChange={onSelectChange}
          styles={customStyles}
          {...rest}
        />

        {withButton && (
          <Button
            type="primary"
            noPadding
            disabled={isButtonDisabled}
            onClick={handleButtonClick}
          >
            Dodaj
          </Button>
        )}
      </div>
    </div>
  );
};

export default Select;
