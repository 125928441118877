import { APP_CONFIG } from "./env_config";

export const API_URL = APP_CONFIG.api.URL;

export enum UserRole {
  "ROOT" = "ROOT",
  "ADMIN" = "ADMIN",
  "MENTOR" = "MENTOR",
  "TEACHER" = "TEACHER",
  "STUDENT" = "STUDENT",
  "ALUMNI" = "ALUMNI",
}
