import { createContext, useMemo, useState } from "react";

export const GroupContext = createContext({
  students: [],
  studentsLength: 0,
  currentEditingIndex: null,
  setStudents: null,
  setCurrentEditingIndex: null,
});

export const GroupContextProvider = ({ children }) => {
  const [students, setStudents] = useState([]);
  const [currentEditingIndex, setCurrentEditingIndex] = useState([]);

  const value = useMemo(
    () => ({
      students,
      studentsLength: students.length,
      setStudents,
      currentEditingIndex,
      setCurrentEditingIndex,
    }),
    [students, currentEditingIndex],
  );

  return (
    <GroupContext.Provider value={value}>{children}</GroupContext.Provider>
  );
};
