import TrimesterChoice from "../TrimesterSwitch/TrimesterSwitch";
import ProfileNavigation from "../Profile/Profile";
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";

export const ContextMenu = () => {
  const history = useHistory();
  const location = useLocation();

  const isHomepage = location.pathname === "/";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isHomepage ? "flex-end" : "space-between",
        borderBottom: "2px solid #004e8d",
        padding: "10px 0",
        marginBottom: "15px",
      }}
    >
      {!isHomepage && (
        <FontAwesomeIcon
          onClick={() => history.goBack()}
          icon={faArrowCircleLeft}
          cursor="pointer"
          size="2x"
          color="#004e8d"
        />
      )}
      <Box
        sx={{
          display: "flex",
        }}
      >
        <TrimesterChoice />
        <ProfileNavigation />
      </Box>
    </Box>
  );
};
