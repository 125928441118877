import { ReactElement, useContext } from "react";
import { TrimestersContext } from "../../../contexts/TrimestersContext";
import Select from "react-select";
import { withRouter } from "react-router-dom";

const customSelectStyles = {
  container: (provided) => ({
    ...provided,
    padding: "0 10px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  control: (provided) => ({
    ...provided,
    width: "max-content",
    background: "transparent",
    color: "white",
    marginRight: 15,
  }),
};

const TrimesterSwitch = ({ history, location }): ReactElement => {
  const { initialTrimesters, activeTrimesterId, setActiveTrimesterId } =
    useContext(TrimestersContext);

  const parseOptions = () =>
    initialTrimesters.map((trimester) => ({
      value: trimester.trimester_id,
      label: trimester.code,
    }));

  const changeTrimesterContext = (selectedTrimester) => {
    window.sessionStorage.setItem("trimesterId", selectedTrimester.value);
    setActiveTrimesterId(selectedTrimester.value);

    history.push(location.pathname);
    history.go(0);
  };

  return (
    <Select
      options={parseOptions()}
      isSearchable={false}
      onChange={changeTrimesterContext}
      menuPlacement="top"
      styles={customSelectStyles}
      placeholder="Zmień trymestr"
      value={parseOptions().find(
        (trimester) => trimester.value === activeTrimesterId,
      )}
    />
  );
};

export default withRouter(TrimesterSwitch);
