import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import AuthContainer from "./AuthContainer";
import Box from "@mui/material/Box";
import { useContext, useEffect, useState } from "react";
import { loginUser } from "../../api/authClient";
import Alert, { AlertColor } from "@mui/material/Alert";
import logo from "../../assets/logo.svg";

import styles from "./Login.module.css";
import { UserContext } from "../../contexts/UserContextProvider";
import { withRouter, useLocation, Link } from "react-router-dom";
import emailValidator from "../../validators/emailValidator";

const Login = ({ history }) => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [alert, setAlert] = useState<{ type: AlertColor; text: string } | null>(
    null,
  );
  const [emailError, setEmailError] = useState(false);
  const { setIsLoggedIn, setUser, setRole } = useContext(UserContext);
  const queryParams = useLocation().search;
  const login = async (e) => {
    e.preventDefault();
    try {
      setAlert(null);
      const response = await loginUser(credentials);
      if (response.status === 401) {
        setAlert({ type: "error", text: "Niepoprawne dane logowania" });
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
        setUser(response);
        setRole(response.role);
        history.push("/");
      }
    } catch (e) {
      setAlert({
        type: "error",
        text: `Wystąpił nieoczekiwany błąd, spróbuj ponownie później`,
      });
    }
  };

  useEffect(() => {
    if (queryParams.includes("tokenInvalid")) {
      setAlert({ type: "error", text: `Token wygasł, lub jest niepoprawny` });
    } else if (queryParams.includes("passwordChanged")) {
      setAlert({ type: "success", text: `Twoje hasło zostało zmienione` });
    } else if (queryParams.includes("tokenSent")) {
      setAlert({
        type: "success",
        text: "Na Twój adres e-mail wysłaliśmy link do ustawienia nowego hasła",
      });
    }
  }, []);

  return (
    <AuthContainer>
      <Card color="white">
        <div className={styles.logoHandler}>
          <img alt="Logo IDER" src={logo} className={styles.logo} />
        </div>
        {alert && <Alert severity={alert.type}>{alert.text}</Alert>}
        <form onSubmit={(e) => login(e)}>
          <Box display="flex" flexDirection="column" padding="16px">
            <Box marginBottom="16px">
              <TextField
                variant="outlined"
                id="email"
                label="E-mail"
                fullWidth
                error={emailError}
                helperText={
                  emailError ? "Adres e-mail jest niepoprawny" : false
                }
                onBlur={(e) => setEmailError(!emailValidator(e.target.value))}
                onChange={(e) =>
                  setCredentials({ ...credentials, email: e.target.value })
                }
              />
            </Box>
            <Box marginBottom="16px" width="100%">
              <TextField
                variant="outlined"
                id="password"
                label="Hasło"
                type="password"
                fullWidth
                onChange={(e) =>
                  setCredentials({ ...credentials, password: e.target.value })
                }
              />
            </Box>
            <Box
              marginBottom="16px"
              width="100%"
              display="flex"
              alignItems="center"
            >
              <Button
                type="submit"
                disabled={emailError}
                variant="outlined"
                color="primary"
              >
                Wyślij
              </Button>

              <Box marginLeft="16px">
                <Link to="/resetuj-haslo">Zresetuj swoje hasło</Link>
              </Box>
            </Box>
          </Box>
        </form>
      </Card>
    </AuthContainer>
  );
};

export default withRouter(Login);
