import CssBaseline from "@mui/material/CssBaseline";
import React, { ReactElement } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import UserContextProvider from "./contexts/UserContextProvider";
import Login from "./modules/auth/Login";
import Home from "./modules/home/Home";
import ProtectedRoute from "./components/ProtectedRoute";
import PasswordReset from "./modules/auth/PasswordReset";
import PasswordForm from "./modules/auth/PasswordForm";
import { ModalProvider } from "./contexts/ModalContext";
import UsersContextProvider from "./contexts/UsersContextProvider";

import "typeface-roboto";
import TrimestersContext from "./contexts/TrimestersContext";
import { GroupContextProvider } from "./contexts/GroupContext";

function App(): ReactElement {
  return (
    <UsersContextProvider>
      <UserContextProvider>
        <ModalProvider>
          <GroupContextProvider>
            <BrowserRouter>
              <CssBaseline />
              <Switch>
                <ProtectedRoute
                  isAuthPage
                  exact
                  path="/logowanie"
                  component={Login}
                />
                <ProtectedRoute
                  isAuthPage
                  exact
                  path="/resetuj-haslo"
                  component={PasswordReset}
                />
                <Route exact path="/ustaw-haslo" component={PasswordForm} />
                <TrimestersContext>
                  <ProtectedRoute path="/" component={Home} />
                </TrimestersContext>
              </Switch>
            </BrowserRouter>
          </GroupContextProvider>
        </ModalProvider>
      </UserContextProvider>
    </UsersContextProvider>
  );
}

export default App;
