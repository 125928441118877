import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "../../components/Button/Button";

import "./SchoolYears.css";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { addSchoolYear } from "../../api/schoolYearsClient";
import { ModalContext } from "../../contexts/ModalContext";

export const SchoolYearForm = ({ updateSchoolYears }) => {
  const { hideModal } = useContext(ModalContext);
  const [years, setYears] = useState({
    year_started_at: "",
    year_ended_at: "",
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const isYearsGiven = years.year_ended_at && years.year_started_at;

  useEffect(() => {
    validateYears();
  }, [years]);

  const validateYears = () => {
    if (isYearsGiven) {
      if (+years.year_ended_at < +years.year_started_at) {
        setErrorMessage(
          "Rok zakończenia roku szkolnego musi być później niż początku.",
        );
      }

      if (isNaN(+years.year_ended_at) || isNaN(+years.year_started_at)) {
        setErrorMessage("Rok rozpoczęcia oraz końca musi być liczbą.");
      }

      if (years.year_ended_at.length > 4 || years.year_started_at.length > 4) {
        setErrorMessage("Podany rok musi składać się z czterech cyfr.");
      }
    }
  };

  const handleInputChange = (event) => {
    setErrorMessage(null);
    setYears(() => ({ ...years, [event.target.id]: event.target.value }));
  };

  const onSave = async () => {
    const response = await addSchoolYear(years);
    updateSchoolYears(response);
    hideModal();
  };

  return (
    <>
      <h1>Dodaj rok szkolny</h1>

      <Box marginBottom="16px">
        <TextField
          variant="outlined"
          id="year_started_at"
          label="Rok rozpoczęcia"
          fullWidth
          onChange={handleInputChange}
          value={years["year_started_at"]}
        />
      </Box>
      <Box marginBottom="16px">
        <TextField
          variant="outlined"
          id="year_ended_at"
          label="Rok zakończenia"
          fullWidth
          onChange={handleInputChange}
          value={years["year_ended_at"]}
        />
      </Box>
      <ErrorMessage message={errorMessage} />
      <Box marginBottom="16px" width="100%">
        <Button
          disabled={!isYearsGiven || !!errorMessage}
          onClick={onSave}
          type="primary"
        >
          Wyślij
        </Button>
      </Box>
    </>
  );
};
