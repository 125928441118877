import { API_URL } from "../constants";

export const loginUser = async (payload: any) => {
  try {
    const response = await global.fetch(`${API_URL}/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const logoutUser = async () => {
  try {
    await global.fetch(`${API_URL}/auth/logout`, {
      method: "GET",
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
};

export const checkIsLoggedIn = async () => {
  try {
    const response = await global.fetch(`${API_URL}/auth/logged_in`, {
      method: "GET",
      credentials: "include",
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const passwordReset = async (email) => {
  try {
    const response = await global.fetch(`${API_URL}/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    return response;
  } catch (e) {
    console.log(e);
  }
};

export const validateToken = async (token) => {
  try {
    const response = await global.fetch(
      `${API_URL}/validate-token?token=${token}`,
      {
        method: "GET",
        credentials: "include",
      },
    );

    return response;
  } catch (e) {
    console.log(e);
  }
};

export const changePassword = async (password, token) => {
  try {
    const response = await global.fetch(
      `${API_URL}/change-password?token=${token}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ password }),
      },
    );

    return response;
  } catch (e) {
    console.log(e);
  }
};
