import AuthContainer from "./AuthContainer";
import Card from "@mui/material/Card";
import logo from "../../assets/logo.svg";
import styles from "./Login.module.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useContext, useEffect, useState } from "react";
import {
  changePassword,
  logoutUser,
  validateToken,
} from "../../api/authClient";
import { useLocation, withRouter } from "react-router-dom";
import { UserContext } from "../../contexts/UserContextProvider";

const PasswordForm = ({ history }) => {
  const [password, setPassword] = useState("");
  const [repeat, setRepeat] = useState("");
  const [isValid, setIsValid] = useState(false);
  const useLocationSearch = useLocation().search;
  const { isLoggedIn, isLoading } = useContext(UserContext);
  const token = new URLSearchParams(useLocationSearch).get("token");
  const validate = () => {
    return password && repeat && password === repeat;
  };

  const isTokenValid = async () => {
    const response = await validateToken(token);
    if (response.status >= 400) {
      history.push("/logowanie?tokenInvalid");
    }
  };

  const handleSave = async () => {
    try {
      await changePassword(password, token);
      if (isLoggedIn) {
        await logoutUser();
      }
      history.push("/logowanie?passwordChanged");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      isTokenValid();
    }
  }, [isLoading]);

  useEffect(() => {
    setIsValid(validate());
  }, [password, repeat]);

  return (
    <AuthContainer>
      <Card color="white">
        <div className={styles.logoHandler}>
          <img alt="Logo IDER" src={logo} className={styles.logo} />
        </div>
        <Box display="flex" flexDirection="column" padding="16px">
          <Box marginBottom="16px">
            <TextField
              variant="outlined"
              id="password"
              label="Hasło"
              type="password"
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Box marginBottom="16px">
            <TextField
              variant="outlined"
              id="password"
              type="password"
              label="Potwierdź hasło"
              fullWidth
              onChange={(e) => setRepeat(e.target.value)}
            />
          </Box>
          <Box marginBottom="16px" width="100%">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSave}
              disabled={!isValid}
            >
              Ustaw hasło
            </Button>
          </Box>
        </Box>
      </Card>
    </AuthContainer>
  );
};

export default withRouter(PasswordForm);
