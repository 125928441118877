import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import ButtonMUI from "@mui/material/Button";

import Container from "../../components/Container";
import { deleteUser, getUsers } from "../../api/usersClient";
import { ModalContext } from "../../contexts/ModalContext";
import UserDetailsModal from "./UserDetailsModal";
import { UsersContext } from "../../contexts/UsersContextProvider";
import getRoleNicename from "../../helpers/getRoleNicename";
import getRoleColor from "../../helpers/getRoleColor";
import ConfirmationModal from "../../components/ConfirmationModal";
import Button from "../../components/Button/Button";

import { withRouter } from "react-router-dom";
import { UserContext } from "../../contexts/UserContextProvider";

import "./Users.scss";
import Search from "../summaries/Search";
import { ServerMessage } from "../../components/ServerMessage/ServerMessage";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Badge,
} from "@mui/material";
import {UsersColumnType} from "../../models/TableModel";

const Users = ({ history, location }) => {
  const { showModal } = useContext(ModalContext);
  const { users, setUsers } = useContext(UsersContext);
  const { isAdmin, isRoot, isMentor } = useContext(UserContext);
  const [serverMessage, setServerMessage] = useState(null);
  const [onlyStudentsFlag, setOnlyStudentsFlag] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns: readonly UsersColumnType[] = [
    { id: "name", label: "Imię i nazwisko" },
    { id: "email", label: "E-mail" },
    { id: "role", label: "Rola" },
    {
      id: "actions",
      label: "",
      align: "right",
    },
  ];

  const _fetchUsers = async (query = null) => {
    try {
      const fetchedUsers = await getUsers(
        isMentor || onlyStudentsFlag ? "STUDENT" : "",
        query,
      );
      setUsers(fetchedUsers.users);
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message: "Nie udało się pobrać użytkowników.",
      });
    }
  };

  const showDetailsModal = (user = null) => {
    showModal(UserDetailsModal, user ? { userDetails: user } : {});
  };

  const handleUserDelete = async (userId) => {
    try {
      await deleteUser(userId).then((_) => {
        setUsers((users) => users.filter((user) => user.user_id !== userId));
        setServerMessage({
          type: "SUCCESS",
          message: "Użytkownik został usunięty.",
        });
      });
    } catch (err) {
      setServerMessage({
        type: "ERROR",
        message: "Nie udało się usunąć użytkownika. Spróbuj ponownie później.",
      });
    }
  };

  const showDeleteModal = (userId) => {
    showModal(ConfirmationModal, {
      action: () => handleUserDelete(userId),
      text: "Czy na pewno chcesz usunąć użytkownika? Operacja jest nieodwracalna",
    });
  };

  const goToProfile = (userId) => history.push(`/profil/${userId}`);

  useEffect(() => {
    _fetchUsers();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search).get("query");
    _fetchUsers(query);
  }, [location.search]);

  useEffect(() => {
    if (onlyStudentsFlag !== null) {
      setUsers([]);
      _fetchUsers();
    }
  }, [onlyStudentsFlag]);

  if (!users) {
    return null;
  }

  return (
    <Container>
      <Box className="topControls">
        <h1>{isMentor ? "Uczniowie" : "Użytkownicy"}</h1>
        {(isAdmin || isRoot) && (
          <Box className="topControls">
            <Button
              mobileFullWidth
              withRightMargin
              onClick={() => setOnlyStudentsFlag(!onlyStudentsFlag)}
              type="secondary"
            >
              {onlyStudentsFlag ? "Pokaż wszystkich" : "Pokaż uczniów"}
            </Button>
            <Button
              mobileFullWidth
              type="primary"
              onClick={() => showDetailsModal()}
            >
              Dodaj
            </Button>
          </Box>
        )}
      </Box>

      <Search />

      <ServerMessage messageObj={serverMessage} />

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#004e8d",
                }}
              />
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    backgroundColor: "#004e8d",
                    color: "#fff",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow hover tabIndex={-1} key={user.user_id}>
                  <TableCell>
                    <Badge
                      badgeContent={
                        <Tooltip title="Liczba zalogowań">
                          {user.login_count || "0"}
                        </Tooltip>
                      }
                      color="primary"
                      aria-label="Liczba zalogowań"
                    >
                      <Avatar alt={`${user.first_name} ${user.last_name}`} src={`/avatars/${user.avatar_url}` || undefined} />
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <b>{user.first_name} {user.last_name}</b>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Chip label={getRoleNicename(user.role)}  sx={{
                      backgroundColor: getRoleColor(user.role),
                      color: '#fff'
                    }} />
                  </TableCell>
                  <TableCell align="right">
                    <ButtonMUI
                        variant="outlined"
                        size="small"
                        sx={{marginRight: '8px'}}
                        onClick={() => goToProfile(user.user_id)}
                    >
                      Profil
                    </ButtonMUI>
                    {(isAdmin || (isRoot && !user.archived)) && (
                        <>
                          <ButtonMUI
                              variant="outlined"
                              color="error"
                              size="small"
                              onClick={() => showDeleteModal(user.user_id)}
                          >
                            Archiwizuj
                          </ButtonMUI>
                        </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          backgroundColor: "rgba(239, 239, 239, 0.7)",
        }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Wierszy na stronę:"
        labelDisplayedRows={({ from, to, count }) => {
          return "" + from + "-" + to + " z " + count;
        }}
      />
    </Container>
  );
};

export default withRouter(Users);
