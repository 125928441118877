import { API_URL } from "../constants";

export const getSummaries = async (query = "") => {
  try {
    const response = await global.fetch(
      `${API_URL}/summaries${!!query ? `?query=${query}` : ""}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getSummary = async ({ subjectId, userId }) => {
  try {
    const response = await global.fetch(
      `${API_URL}/summaries/${subjectId}/user/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const saveSummary = async ({ subjectId, userId, summary }) => {
  try {
    const response = await global.fetch(
      `${API_URL}/summaries?subjectId=${subjectId}&userId=${userId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(summary),
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const updateSummary = async (summaryId, descriptionObj) => {
  try {
    const response = await global.fetch(`${API_URL}/summaries/${summaryId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(descriptionObj),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const changePublishState = async (summary) => {
  try {
    const response = await global.fetch(
      `${API_URL}/summaries/change-publish-state`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(summary),
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};
