import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useContext, useState } from "react";
import Button from "../../components/Button/Button";
import { ModalContext } from "../../contexts/ModalContext";
import { saveCategory, updateCategory } from "../../api/categoriesClient";

const SubjectCategoryForm = ({
  categoryDetails = null,
  updateSubjectCategory,
  setServerMessage,
}) => {
  const [category, setCategory] = useState({
    name: categoryDetails?.name || null,
    color: categoryDetails?.color || "#fff",
  });
  const { hideModal } = useContext(ModalContext);

  const handleInputChange = (e) =>
    setCategory({ ...category, name: e.target.value });

  const handleSave = async () => {
    try {
      if (categoryDetails?.subject_cat_id) {
        try {
          await updateCategory(categoryDetails.subject_cat_id, {
            ...categoryDetails,
            ...category,
          });
          updateSubjectCategory(
            { ...categoryDetails, ...category },
            categoryDetails?.subject_cat_id,
          );
          setServerMessage({
            type: "SUCCESS",
            message: "Pomyślnie zaktualizowano blok przedmiotów.",
          });
        } catch (err) {
          setServerMessage({
            type: "ERROR",
            message: "Nie udało się zaktualizować bloku przedmiotów.",
          });
        }
      } else {
        try {
          const response = await saveCategory(category);
          updateSubjectCategory({
            ...category,
            created_at: response.created_at,
          });
          setServerMessage({
            type: "SUCCESS",
            message: "Pomyślnie dodano blok przedmiotów.",
          });
        } catch (err) {
          setServerMessage({
            type: "ERROR",
            message: "Nie udało się dodać bloku przedmiotów.",
          });
        }
      }
    } catch (e) {
      console.log(e);
    }

    hideModal();
  };

  return (
    <React.Fragment>
      <h1>{categoryDetails ? "Edytuj kategorię" : "Dodaj kategorię"}</h1>
      <Box marginBottom="16px">
        <TextField
          variant="outlined"
          id="category_name"
          label="Nazwa"
          fullWidth
          onChange={handleInputChange}
          value={category?.name || ""}
        />
      </Box>
      <Box marginBottom="16px">
        <input
          type="color"
          value={category?.color}
          onChange={(e) => setCategory({ ...category, color: e.target.value })}
        />
      </Box>
      <Box marginBottom="16px" width="100%">
        <Button onClick={handleSave} type="primary">
          Wyślij
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default SubjectCategoryForm;
