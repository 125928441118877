import { Redirect, Route } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContextProvider";
import { UserRole } from "../constants";

const ALL_ROLES = [
  UserRole.ROOT,
  UserRole.ADMIN,
  UserRole.MENTOR,
  UserRole.TEACHER,
  UserRole.STUDENT,
];

const ProtectedRoute = ({
  component: Component,
  isAuthPage = false,
  roles = ALL_ROLES,
  ...rest
}) => {
  const { isLoggedIn, isLoading, role } = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoading) {
          if (isAuthPage) {
            return isLoggedIn ? <Redirect to="/" /> : <Component {...props} />;
          } else {
            if (isLoggedIn) {
              if (roles.includes(role)) {
                return <Component {...props} />;
              } else {
                return <Redirect to="/" />;
              }
            } else {
              return <Redirect to="/logowanie" />;
            }
          }
        }
      }}
    />
  );
};

export default ProtectedRoute;
