import React, { createContext, useMemo, useState } from "react";

export const UsersContext = createContext({
  users: [],
  setUsers: (users: any) => {},
});

const UsersContextProvider = ({ children }) => {
  const [users, setUsers] = useState(null);

  const value = useMemo(
    () => ({
      users,
      setUsers,
    }),
    [users],
  );

  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};

export default UsersContextProvider;
