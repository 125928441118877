import React, { createContext, useEffect, useMemo, useState } from "react";
import { checkIsLoggedIn } from "../api/authClient";
import { UserRole } from "../constants";

export const UserContext = createContext({
  user: null,
  isLoggedIn: false,
  setIsLoggedIn: null,
  setUser: null,
  role: null,
  setRole: null,
  clearUser: null,
  isLoading: true,
  isRoot: false,
  isAdmin: false,
  isMentor: false,
  isTeacher: false,
  isStudent: false,
  isManagement: false,
});

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const _checkLoggedIn = async () => {
    setIsLoading(true);
    try {
      const userData = await checkIsLoggedIn();
      setUser({ ...userData.user });
      setIsLoggedIn(userData.status);
      setRole(userData.user.role);
      setIsLoading(false);
    } catch (error) {
      setUser(null);
      setRole(null);
      setIsLoggedIn(false);
      setIsLoading(false);
    }
  };

  const isManagement =
    role === UserRole.ROOT ||
    role === UserRole.ADMIN ||
    role === UserRole.MENTOR;

  const clearUser = () => {
    setUser(null);
    setRole(null);
    setIsLoggedIn(false);
  };

  useEffect(() => {
    _checkLoggedIn();
  }, []);

  const value = useMemo(
    () => ({
      user,
      isLoggedIn,
      setIsLoggedIn,
      role,
      isRoot: role === UserRole.ROOT,
      isAdmin: role === UserRole.ADMIN,
      isMentor: role === UserRole.MENTOR,
      isTeacher: role === UserRole.TEACHER,
      isStudent: role === UserRole.STUDENT,
      isManagement,
      setUser,
      clearUser,
      setRole,
      isLoading,
    }),
    [user, isLoggedIn, role, isLoading],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
