import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useContext, useState } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import Button from "../../components/Button/Button";
import { saveGroup, updateGroup } from "../../api/userGroupsClient";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

const GroupForm = ({ groupDetails, updateUserGroups, setServerMessage }) => {
  const [group, setGroup] = useState({
    name: groupDetails?.name || null,
    code: groupDetails?.code || null,
    type: groupDetails?.type || null,
  });
  const { hideModal } = useContext(ModalContext);

  const handleSelectChange = (e) => {
    setGroup({ ...group, type: e.target.value });
  };

  const handleSave = async () => {
    if (groupDetails?.group_id) {
      try {
        await updateGroup(groupDetails.group_id, { ...groupDetails, ...group });
        setServerMessage({
          type: "SUCCESS",
          message: "Pomyślnie zaktualizowano grupę.",
        });
        updateUserGroups({ ...groupDetails, ...group }, groupDetails?.group_id);
      } catch (err) {
        setServerMessage({
          type: "ERROR",
          message:
            "Nie udało się zaktualizować grupy. Spróbuj ponownie za chwilę.",
        });
      }
    } else {
      try {
        const newGroup = await saveGroup(group);

        setServerMessage({
          type: "SUCCESS",
          message: "Pomyślnie utworzono grupę.",
        });
        updateUserGroups({ group_id: newGroup?.group_id, ...group }, null);
      } catch (err) {
        setServerMessage({
          type: "ERROR",
          message: "Nie udało się utworzyć grupy. Spróbuj ponownie za chwilę.",
        });
      }
    }

    hideModal();
  };

  return (
    <React.Fragment>
      <h1>{groupDetails?.group_id ? "Edytuj grupę" : "Dodaj grupę"}</h1>
      <Box marginBottom="16px">
        <TextField
          variant="outlined"
          id="group_code"
          label="Nazwa grupy"
          fullWidth
          onChange={(e) => setGroup({ ...group, name: e.target.value })}
          value={group?.name || ""}
        />
      </Box>
      <Box marginBottom="16px">
        <TextField
          variant="outlined"
          id="group_code"
          label="Kod grupy"
          fullWidth
          onChange={(e) => setGroup({ ...group, code: e.target.value })}
          value={group?.code || ""}
        />
      </Box>
      <Box>
        <FormControl
          variant="outlined"
          sx={{
            minWidth: 120,
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <InputLabel htmlFor="group-select">Typ grupy</InputLabel>
          <Select
            native
            value={group?.type}
            onChange={handleSelectChange}
            label="Typ grupy"
            inputProps={{
              name: "group",
              id: "group-select",
            }}
          >
            <option aria-label="None" value="" />
            <option value="STUDENT">Uczniowska</option>
            <option value="STAFF">Nauczycielska</option>
          </Select>
        </FormControl>
      </Box>
      <Box marginBottom="16px" width="100%">
        <Button onClick={handleSave} type="primary">
          Wyślij
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default GroupForm;
